//import React from "react";
import React, { Component } from 'react';
//import Foo from "./components/Foo";
//import Bar from "./components/Bar";

import TableCom from '../components/TableComp'
//import TableCom from '../components/TableExample'
import FormCom from '../components/FormComp'

const Components = {
  table: TableCom,
  form: FormCom
};

export default props => {
  if (typeof Components[props.comp] !== "undefined") {
    return React.createElement(Components[props.comp], {
      key: props._uid,
      block: props,
      source: props.source,
      parent_id: props.parent_id
    });
  }
  return React.createElement(
    () => <div>The component {props.comp} has not been created yet.</div>,
    { key: props._uid }
  );
};

/*
class DynaComponent extends React.Component {
  constructor(props, context) {
      super(props, context);
  }
  render() {
    if (typeof Components[this.props.block.comp] !== "undefined") {
      return React.createElement(Components[this.props.block.comp], {
        key: this.props.block._uid,
        block: this.props.block,
        source: this.props.block.source
      });
    }
    return React.createElement(
      () => <div>The component {this.props.block.comp} has not been created yet.</div>,
      { key: this.props.block._uid }
    );
  }
}

export default DynaComponent
*/