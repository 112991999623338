import { themeConstants } from '../_constants'

const initialState = {
    theme: 'materia'
}

export function theme(state = {}, action) {
    switch (action.type) {
        case themeConstants.APPLY_THEME:
          return Object.assign({ theme: action.payload } );
        default:
          return state;
      }
  }
  