import React, { Component } from "react";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { LinkContainer } from "react-router-bootstrap";

//import Spinner from 'react-bootstrap/Spinner';
//import Button from 'react-bootstrap/Button'

import SlidingPane from 'react-sliding-pane';
import ModalReact from 'react-modal';

import Components from "../_helpers/components"

import FormCom from './FormComp'

import {API_URL_REST} from '../_constants/app.constants';

const apiUrlRest = API_URL_REST;

class SlidePanelComp extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          isLoading: false,
          el_id: false,
          parent_id: false,
          source: '',
          layout: '',
          row_current: '',
          isPaneOpen: false,
          modalDeleteShow: false
        };
        this.handleModalClose = this.handleModalClose.bind(this);
        this.deleteEl = this.deleteEl.bind(this);
        ModalReact.setAppElement('#root')
    }

	componentDidMount() {
        //ModalReact.setAppElement('#root')
        this.setState({isPaneOpen: this.props.isPaneOpen, row_current: this.props.row})
    }

    //static getDerivedStateFromProps(nextProps, prevState){
        /*
        if(nextProps.isPaneOpen!==prevState.isPaneOpen){
          return { isPaneOpen: nextProps.isPaneOpen};
       }
       else return null;
       */
    //}

    componentDidUpdate() {
        //console.log('qui')
        //if(this.state.isPaneOpen != this.props.isPaneOpen)
            //this.setState({isPaneOpen: this.props.isPaneOpen, row_current: this.props.row})
    }

    //isPaneOpen = false

    deleteEl(){
        if(typeof this.props.source === 'object') {
            //sourc is proc, pass layout to find delete source in server side
            var bodyobj = {layout: this.props.layout, id: this.props.row.ID}
        } else {
            //source is db table
            var bodyobj = {source: this.props.source, id: this.props.row.ID}
        }
        var body = JSON.stringify(bodyobj);
        fetch(apiUrlRest+'delete', {
          method: 'post',
          headers: {'Content-Type':'application/json'},
          body: body
        })
        // We get the API response and receive data in JSON format...
        .then(response => response.json())
        // ...then we update the users state
        .then(data => {
          var res = data.data     
          this.setState({
            modalDeleteShow: false,
            isLoading: false,
            isPaneOpen: false
          })
          this.props.refresh()
          this.props.slidePane(false)
          }
        )
        .catch(error => this.setState({ error, isLoading: false }));
    }
  
    handleModalClose(){
        this.setState({
            modalDeleteShow: false,
        })
    }

    paneHeader(){
        return <Navbar>
            <Navbar.Brand href="#home">Dettaglio</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
                <Button variant="outline-danger" onClick={(event) => this.setState({modalDeleteShow: true})}>Elimina</Button>
                {(!!this.props.row.PATH)?
                    <LinkContainer to={{
                        pathname: this.props.row.PATH,
                        state: {el_id:this.props.row.el_id}}}
                        >
                        <Nav.Link>Vai all'Elemento</Nav.Link>
                    </LinkContainer>
                    :
                    ''
                }
            </Navbar.Collapse>
        </Navbar>
    }

    render () {
        let currentPanel
        //var { isPaneOpen } = this.props
        //this.isPaneOpen = this.props.isPaneOpen
        //var { isPaneOpen } = this.state
        if (!this.props.isPaneOpen){
            var isOpen = false;
        } else {
            var isOpen = this.props.isPaneOpen;
        }

        return (
            <div>
                <SlidingPane
                //ref={(this) => {currentPanel = this;}}
                className='some-custom-class'
                overlayClassName='some-custom-overlay-class'
                //isOpen={ this.state.isPaneOpen }
                //isOpen={this.props.isPaneOpen}
                isOpen={isOpen}
                title={this.paneHeader()}
                subtitle='modifica i dettagli'
                onRequestClose={ () => {
                    // triggered on "<" on left top click or on outside click
                    //this.setState({ isPaneOpen: false });
                    //this.props.isPaneOpen = false;
                    this.props.slidePane(false)
                } }>
                {(!!this.props.comp && Components(this.props.comp)) ?
                    Components(this.props)
                :
                (!!this.props.sourceNew) ?
                    <FormCom el_id={this.props.row.ID} parent_id={this.props.parent_id} source={this.props.source} sourceNew={{"layout":this.props.sourceNew.layout}} refresh={this.props.refresh}/>
                :
                    <FormCom el_id={this.props.row.ID} parent_id={this.props.parent_id} source={this.props.source} layout={this.props.layout} refresh={this.props.refresh}/>
                }

                </SlidingPane>
                <Modal size="sm" show={this.state.modalDeleteShow} onHide={this.handleModalClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Cancella Elemento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Vuoi davvero cancellare questo elemento?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="link" onClick={this.handleModalClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={this.deleteEl}>
                        Cancellalo!
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
  }

  export default SlidePanelComp