import React, { useState, Fragment } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
//import Modal from 'react-modal';
//import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { history } from '../_helpers';

//import Components from "../_helpers/components"
//import TableCom from './TableComp'
import FormCom from './FormComp'
//import FormPaymentsComp from './FormPaymentsComp'
//import TablelistComp from './TablelistComp'
//import ToursPeoplesImportComp from './ToursPeoplesImportComp'

class QuotationsDetailsComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        key: 'details',
        viaggio_corrente: this.props.location.state.el_id,
        //viaggio_corrente: '',
        row_current: '',
        isPaneOpen: false,
      };
      this.handleSelect = this.handleSelect.bind(this);
      this.openSlidePanel = this.openSlidePanel.bind(this);
      if(this.state.viaggio_corrente == false) {
        history.push({
          pathname: '/quotations/list',
          state: {source:'wp_cl_quotations'}
        })
      }
    }
  
    componentDidMount() {
      //Modal.setAppElement(this.el);
      //this.setState({viaggio_corrente: this.props.location.state.el_id})
    }

    //shouldComponentUpdate() {return false}
    componentDidUpdate(prevProps, prevState, snapshot) {
      //console.log('qui componentDidUpdate');
      if (this.props.location.state.el_id !== prevProps.location.state.el_id) {
        this.setState({viaggio_corrente: this.props.location.state.el_id});
      }
    }
    
    componentWillUnmount() {}

    openSlidePanel(row){
      this.setState({isPaneOpen: true})
      this.setState({row_current: row})
    }

    handleSelect(key, viaggio_id) {
      //alert('selected ' + key);
      //this.setState({key: key, viaggio_corrente: viaggio_id })
      this.setState({key: key})
    }

    render() {
      const tablewhere = "TK_QUOTATIONS = "+this.props.location.state.el_id;
      return (
        <div ref={ref => this.el = ref}>
        <Tabs
          id="controlled-tab-quotation"
          activeKey={this.state.key}
          onSelect={key => this.setState({ key })}
          mountOnEnter={true}
        >
          <Tab eventKey="details" title="Dettaglio">
            <FormCom el_id={this.props.location.state.el_id} source="wp_cl_quotations"/>
          </Tab>
          <Tab eventKey="tourattributes" title="Attributi Viaggio">
            <FormCom el_id={this.props.location.state.el_id} source={{type:'proc', proc:'attributesTour', table:'wp_cl_quotations'}} layout='20'/>
          </Tab>
          <Tab eventKey="companies" title="Fornitori" >
            {/*<TableCom el_id={this.props.location.state.el_id} source='wp_cl_companiestotravels' sourceNew={{layout:'7'}} layoutForm='8' panelBlock='table' panelProc="TourCliForn"/>*/}
            <FormCom el_id={this.props.location.state.el_id} source='wp_cl_companiestoquotations' sourceNew={{layout:'7'}} layoutForm='8' TK="TK_QUOTATIONS" GROUPBY="TK_COMPANIES" handleSelect={this.props.handleSelect}/>
            {/*<button onClick={()=>this.handleSelect("details")}>Go to tab Details</button>*/}
          </Tab>
        </Tabs>
        </div>
      );
    }
  }
  
  //render(<ControlledTabs />);
  export default QuotationsDetailsComp