/*
 * AppConstants
 * These are the variables that determine what our central data store (reducer.js)
 * changes in our state. When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */
import React from "react";

export const CHANGE_FORM = 'CHANGE_FORM';
export const SET_AUTH = 'SET_AUTH';
export const SENDING_REQUEST = 'SENDING_REQUEST';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const ERP_ROOT_URL = 'https://cltour.uber.space/';
export const API_URL_LOGIN = 'https://cltour.uber.space/wp-json/sw-rest-api/v1/user_login';
export const API_URL_REST = 'https://cltour.uber.space/wp-json/cltour-rest-api/v1/';

const prod = {
  CLTOUR_URL: window.location.origin,
  FORMBUILDER_URL: window.location.origin+'/formbuilder/',
  BOOKING_URL: window.location.origin+'/booking/',
};
const dev = {
  CLTOUR_URL: 'https://cltour.uber.space/',
  FORMBUILDER_URL: 'https://cltour.uber.space/formbuilder/',
  BOOKING_URL: 'https://cltour.uber.space/booking/',
};

export const URLS = process.env.NODE_ENV === 'development' ? dev : prod

export const schema_layouts_table = {
    title: "Layouts",
    type: "object",
    properties: {
      table: {
        type: "array",
        items: {
          type: "object",
          properties: {
            source:{"type": "string","title":"source", "default":"wp_cl_layouts"},
            ID:{"type": "string", "title":"ID"},
            DESCR:{"type":"string","title": "Descrizione"},
            TYPE:{"type":"string","title":"Tipo"}
          }
        }
      }
    }
}
  
export const uiSchema_layouts_table = {
    table: {
      "ui:field": "table",
      "table": {
        "search":true,
        "multiColumnSearch":true,
        "exportCSV":true,
        "tableCols": [
          {"dataField":"ID", "field": "string", "dataSort":true,"width":"33%","filter":{"type":"TextFilter","delay":1000}},
          {"dataField": "DESCR", "field": "string","width":"33%", "filter":{"type":"TextFilter","delay":1000},"tdStyle":{ "whiteSpace":"normal"}},
          {"dataField":"TYPE","width":"33%","filter":{"type":"TextFilter","delay":1000}},
          {"dataField":"source","hidden":true,"editable":false}
        ]
      }
    }
}

export const schema_travels = {
  title: "Viaggio",
  type: "object",
  required: ["DESCRIPTION","STATUS"],
  properties: {
    source: {type: "string", title: "source", default: "wp_cl_travels"},
    DESCRIPTION: {type: "string", title: "Titolo", default: ""},
    CENTRODICOSTO: {type: "string", title: "Centri di Costo", default: ""},
    //LONGDESCRIPTION: {type: "string", title: "Descrizione", default: "Descrizione del viaggio"},
    TIPOVIAGGIO: {
      type: "string",
      title: "Tipologia Viaggio",
      enum: ['', 'Scuola','Adulti','Adulti-Gruppo','Corso di Formazione','Progetto Culturale Istoreco','Scuola Istoreco'],
      //uniqueItems: true,
      default: ""
    },
    REFERENTE: {
      type: "integer",
      //enum: noPanic(),
      title: "Referente",
      //uniqueItems: true,
      //default: "mg Collegamento Tabella Persone"
    },
    ADDREFERENTE:{type: "string", title: "Aggiungi Persona"},
    //ACCOMPAGNATORE: {type: "integer", title: "Accompagnatore"},
    ACCOMPAGNATORE: {
      title: "Accompagnatore",
      type: "array",
      items: {
        type: "integer",
      },
    },
    METE: {
      type: "integer",
      /*
      type: "array",
      "items": {
        type: "string",
        //enum: noPanic()
      },
      */
      title: "Mete",
      //uniqueItems: true
    },
    ADDMETE:{type: "string", title: "Crea nuova meta"},
    //ENTE: {type: "integer", title: "Ente"},
    ENTE: {
      "title": "Ente",
      "type": "array",
      "items": {
        "type": "integer"
      }
    },
    IMPORTENTI:{type: "string", title: "Enti da SevDesk"},
    MODULIDIDATTICI: {type: "string", title: "Moduli Didattici"},
    DATARICHIESTA: {type: "string", format: "date", title: "Data della Richiesta"},
    SCADINTERNAPREV: {type: "string", format: "date", title: "Scadenza Interna Preventivo"},
    NUMPARTECIPANTIPREV: {type: "integer", title: "Numero Partecipanti Preventivo", default: "0"},
    GRATUITA: {type: "integer", title: "Gratuità", default: false},
    VITTO: {
      type: "string",
      title: "Vitto",
      enum: ['', 'Bed And Breakfast','Half Board','Full Board'],
      //uniqueItems: true,
      default: ""
    },
    ALLOGGIO: {
      type: "string",
      title: "Alloggio",
      enum: ['', 'Ostello','2 Stelle','3 Stelle', '4 Stelle'],
      default: ""
    },
    //DEPOSITO: {type: "integer", title: "Deposito", default: "0"},
    MEZZO: {
      type: "string",
      title: "Mezzo",
      enum: ['','Pullman','Aereo e Pulman','Aereo e Metro','Treno e Pullman','Treno e Metro','Pullman e Nave','Treno e nave','Individuale'],
      default: ""
    },
    /*
    GESTIONEPAGAMENTI: {
      type: "string",
      enum: ['con Acconto','solo Saldo'],
      title: "Gestione Pagamenti",
      //uniqueItems: true,
      default: ""
    },
    */
    DATAINIZIO: {type: "string", format: "date", title: "Data Inizio"},
    DATAFINE: {type: "string", format: "date", title: "Data Fine"},
    PUBBLICO: {type: "boolean", title: "Pubblico", default: false},
    DETAILS: {
      type: "string",
      title: "Dettagli",
      default: "tematiche\nperiodo\nterza cosa"
    },
    GESTIONEISCRITTI: {type: "boolean", title: "Gestione Iscritti", default: false},
    //ASSICANNULVIAG: {type: "boolean", title: "Assicurazione Annullamento Viaggi", default: false},
    GESTIONEPAGINDIVIDUALI: {type: "boolean", title: "Gestione Pagamenti Individuali", default: false},
    //VIAGGIOSCUOLA: {type: "boolean", title: "Viaggio Scuola", default: false},
    STATUS: {
      type: "string",
      title: "Status",
      enum: ['','Attivo-Acconto', 'Confermato-Saldo', 'Chiuso', 'Posticipato', 'Annullato'],
      default: ""
    }
  }
};

export const uiSchema_travels = {
  source:  {
    "ui:widget": "hidden",
    classNames: "hidden",
    nav: "main"
  },
  CENTRODICOSTO: {"ui:placeholder": "Centro di Costo", nav: "main"},
  DESCRIPTION: {"ui:placeholder": "Titolo Viaggio", nav: "main"},
  TIPOVIAGGIO: {nav: "main"},
  DATAFINE: {nav: "main"},
  DATAINIZIO: {nav: "main"},
  DATARICHIESTA: {nav: "main"},
  SCADINTERNAPREV: {nav: "main"},
  ENTE: {
    "nav": "main",
    "ui:field": "asyncTypeahead",
    "ui:forceLabelDisplay": true,
    "asyncTypeahead": {
      "id": "ente",
      "mapping": "ID",
      "multiple": true,
      "source": "wp_cl_companies",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      "placeholder": "Scegli uno o più Enti",
      optionsPath: 'data.data',
      //labelKey:"NAME",
      "labelKey": (option) => `${option.NAME} ${option.COMMONNAME}`,
      //options: [],
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=SURNAME&source=wp_cl_companies`).then(function (res) {
        return res.json();
      }),
    }
  },
  IMPORTENTI:{
    "ui:widget": (props) => {
      return (
        <button type="button" className="btn btn-outline-primary form-control"
        //onClick={(event) => props.onChange(event.target.value)}
        //onClick={() => props.formContext.setState({isPaneOpen: true})}
        onClick={() => {
          fetch("https://cltour.uber.space/wp-json/cltour-rest-api/v1/sevdesk?action=importcontactfromsevdesk")
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            alert('importati')
          });
        }}
        //onClick={() => console.log(props.formContext)}
        >Importa</button>
      );
    }
  },
  METE: {
    nav: "main",
    "ui:field": "asyncTypeahead",
    "asyncTypeahead": {
      "id": "mete",
      mapping: "ID",
      "source": "wp_cl_destinations",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      labelKey:(option) => `${option.NAME}`,
      optionsPath: 'data.data',
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=NAME&source=wp_cl_destinations`).then(function (res) {
        return res.json();
      }),
    }
  },
  ADDMETE: {
    "ui:widget": (props) => {
    return (
      <button type="button" className="btn btn-outline-primary form-control"
      //onClick={(event) => props.onChange(event.target.value)}
      //onClick={() => props.formContext.setState({isPaneOpen: true})}
      onClick={() => props.formContext.currentForm.openSlidePanel({ID:'new'}, 'wp_cl_destinations', '1')}
      //onClick={() => console.log(props.formContext)}
      >Aggiungi</button>
    );
  }},
  REFERENTE: {
    nav: "main",
    "ui:field": "asyncTypeahead",
    "asyncTypeahead": {
      "id": "referente",
      mapping: "ID",
      "source": "wp_cl_peoples",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      labelKey: (option) => `${option.FIRSTNAME} ${option.SURNAME} ${option.PHONE} ${option.EMAIL}`,
      //labelKey: "SURNAME",
      optionsPath: 'data.data',
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=SURNAME&source=wp_cl_peoples`).then(function (res) {
        return res.json();
      }),
    }
  },
  ADDREFERENTE: {
    "ui:widget": (props) => {
    return (
      <button type="button" className="btn btn-outline-primary form-control"
      //onClick={(event) => props.onChange(event.target.value)}
      //onClick={() => props.formContext.setState({isPaneOpen: true})}
      onClick={() => props.formContext.currentForm.openSlidePanel({ID:'new'}, 'wp_cl_peoples', '11')}
      //onClick={() => console.log(props.formContext)}
      >Aggiungi</button>
    );
  }},
  MODULIDIDATTICI: {nav: "main"},
  NUMPARTECIPANTIPREV: {nav: "main"},
  GRATUITA: {nav: "main"},
  ACCOMPAGNATORE: {
    nav: "main",
    "ui:field": "asyncTypeahead",
    "ui:forceLabelDisplay": true,
    "asyncTypeahead": {
      "id": "accompagnatore",
      "mapping": "ID",
      "multiple": true,
      "source": "wp_cl_peoples",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      placeholder:"Scegli uno o più Accompagnatori",
      labelKey: (option) => `${option.FIRSTNAME} ${option.SURNAME}`,
      //labelKey: "SURNAME",
      //options: [],
      optionsPath: 'data.data',
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=FIRSTNAME&source=wp_cl_peoples`).then(function (res) {
        return res.json();
      }),
    }
  },
  DEPOSITO: {nav: "main"},
  MEZZO: {nav: "main", "ui:placeholder": "Tipologie di trasporto"},
  //GESTIONEPAGAMENTI: {nav: "main"},
  DETAILS: {
    "ui:widget": "textarea",
    "ui:options": {
      rows: 6
    },
    "ui:placeholder": "ulteriori dettagli del viaggio",
    nav: "main"
  },
  VITTO: {nav: "main"},
  ALLOGGIO: {nav: "main"},
  GESTIONEISCRITTI: {nav: "main"},
  GESTIONEPAGINDIVIDUALI: {nav: "main"},
  ASSICANNULVIAG: {nav: "main"},
  //VIAGGIOSCUOLA: {nav: "main"},
  PUBBLICO: {nav: "main"},
  STATUS: {nav: "main"},
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
  { 'ui:col': { md: 12, children: [
    { 'ui:group': 'Dettagli Viaggio', 'ui:row': [
      { 'ui:col': { md: 3, children: ['CENTRODICOSTO'] } },
      { 'ui:col': { md: 9, children: ['DESCRIPTION'] } }
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 12, children: ['DETAILS'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['DATAINIZIO'] } },
      { 'ui:col': { md: 6, children: ['DATAFINE'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 4, children: ['ENTE'] } },
      { 'ui:col': { md: 2, children: ['IMPORTENTI'] } },
      { 'ui:col': { md: 4, children: ['REFERENTE'] } },
      { 'ui:col': { md: 2, children: ['ADDREFERENTE'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 4, children: ['METE'] } },
      { 'ui:col': { md: 2, children: ['ADDMETE'] } },
      { 'ui:col': { md: 6, children: ['MODULIDIDATTICI'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 3, children: ['DATARICHIESTA'] } },
      { 'ui:col': { md: 3, children: ['SCADINTERNAPREV'] } },
      { 'ui:col': { md: 6, children: ['NUMPARTECIPANTIPREV'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['GRATUITA'] } },
      { 'ui:col': { md: 6, children: ['ACCOMPAGNATORE'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['TIPOVIAGGIO'] } },
      { 'ui:col': { md: 6, children: ['MEZZO'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 12, children: ['GESTIONEPAGAMENTI'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['VITTO'] } },
      { 'ui:col': { md: 6, children: ['ALLOGGIO'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 2, children: ['GESTIONEISCRITTI'] } },
      { 'ui:col': { md: 2, children: ['GESTIONEPAGINDIVIDUALI'] } },
      { 'ui:col': { md: 2, children: ['PUBBLICO'] } },
      { 'ui:col': { md: 4, children: ['STATUS'] } }
    ] },
  ] } },
] },
}

export const schema_quotations = {
  title: "Preventivo",
  type: "object",
  required: ["DESCRIPTION","STATUS"],
  properties: {
    source: {type: "string", title: "source", default: "wp_cl_quotations"},
    DESCRIPTION: {type: "string", title: "Titolo", default: ""},
    //LONGDESCRIPTION: {type: "string", title: "Descrizione", default: "Descrizione del viaggio"},
    TIPOVIAGGIO: {
      type: "string",
      title: "Tipologia Viaggio",
      enum: ['', 'Scuola','Adulti','Adulti-Gruppo','Corso di Formazione','Progetto Culturale Istoreco','Scuola Istoreco'],
      //uniqueItems: true,
      default: ""
    },
    REFERENTE: {
      type: "integer",
      //enum: noPanic(),
      title: "Referente",
      //uniqueItems: true,
      //default: "mg Collegamento Tabella Persone"
    },
    ADDREFERENTE:{type: "string", title: "Aggiungi Persona"},
    //ACCOMPAGNATORE: {type: "integer", title: "Accompagnatore"},
    ACCOMPAGNATORE: {
      title: "Accompagnatore",
      type: "array",
      items: {
        type: "integer",
      },
    },
    METE: {
      type: "integer",
      /*
      type: "array",
      "items": {
        type: "string",
        //enum: noPanic()
      },
      */
      title: "Mete",
      //uniqueItems: true
    },
    ADDMETE:{type: "string", title: "Crea nuova meta"},
    //ENTE: {type: "integer", title: "Ente"},
    ENTE: {
      title: "Ente",
      type: "array",
      items: {
        type: "integer",
      },
    },
    IMPORTENTI:{type: "string", title: "Enti da SevDesk"},
    MODULIDIDATTICI: {type: "string", title: "Moduli Didattici"},
    DATARICHIESTA: {type: "string", format: "date", title: "Data della Richiesta"},
    SCADINTERNAPREV: {type: "string", format: "date", title: "Scadenza Interna Preventivo"},
    NUMPARTECIPANTIPREV: {type: "integer", title: "Numero Partecipanti Preventivo", default: 0},
    GRATUITA: {type: "integer", title: "Gratuità", default: 0},
    VITTO: {
      type: "string",
      title: "Vitto",
      enum: ['', 'Bed And Breakfast','Half Board','Full Board'],
      //uniqueItems: true,
      default: ""
    },
    ALLOGGIO: {
      type: "string",
      title: "Alloggio",
      enum: ['', 'Ostello','2 Stelle','3 Stelle', '4 Stelle'],
      default: ""
    },
    //DEPOSITO: {type: "integer", title: "Deposito", default: "0"},
    MEZZO: {
      type: "string",
      title: "Mezzo",
      enum: ['', 'Pullman','Aereo e Pulman','Aereo e Metro', 'Treno e Pullman','Treno e Metro','Pullman e Nave','Treno e nave','Individuale'],
      default: ""
    },
    /*
    GESTIONEPAGAMENTI: {
      type: "string",
      enum: ['con Acconto','solo Saldo'],
      title: "Gestione Pagamenti",
      //uniqueItems: true,
      default: ""
    },
    */
    DATAINIZIO: {type: "string", format: "date", title: "Data Inizio"},
    DATAFINE: {type: "string", format: "date", title: "Data Fine"},
    PUBBLICO: {type: "boolean", title: "Pubblico", default: false},
    DETAILS: {
      type: "string",
      title: "Dettagli",
      default: "tematiche\nperiodo\nterza cosa"
    },
    GESTIONEISCRITTI: {type: "boolean", title: "Gestione Iscritti", default: false},
    //ASSICANNULVIAG: {type: "boolean", title: "Assicurazione Annullamento Viaggi", default: false},
    GESTIONEPAGINDIVIDUALI: {type: "boolean", title: "Gestione Pagamenti Individuali", default: false},
    //VIAGGIOSCUOLA: {type: "boolean", title: "Viaggio Scuola", default: false},
    STATUS: {
      type: "string",
      title: "Status",
      enum: ['','Attivo', 'Confermato', 'Rifiutato', 'Annullato', 'Solo Contatto'],
      default: ""
    }
  }
};

export const uiSchema_quotations = {
  source:  {
    "ui:widget": "hidden",
    classNames: "hidden",
    nav: "main"
  },
  DESCRIPTION: {"ui:placeholder": "Titolo Viaggio", nav: "main"},
  TIPOVIAGGIO: {nav: "main"},
  DATAFINE: {nav: "main"},
  DATAINIZIO: {nav: "main"},
  DATARICHIESTA: {nav: "main"},
  SCADINTERNAPREV: {nav: "main"},
  ENTE: {
    "nav": "main",
    "ui:field": "asyncTypeahead",
    "ui:forceLabelDisplay": true,
    "asyncTypeahead": {
      "id": "ente",
      mapping: "ID",
      "multiple": true,
      "source": "wp_cl_companies",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      placeholder: "Scegli uno o più Enti",
      optionsPath: 'data.data',
      //labelKey:"NAME",
      labelKey: (option) => `${option.NAME} ${option.COMMONNAME}`,
      //options: [],
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=NAME&source=wp_cl_companies`).then(function (res) {
        return res.json();
      }),
    }
  },
  IMPORTENTI:{
    "ui:widget": (props) => {
      return (
        <button type="button" className="btn btn-outline-primary form-control"
        //onClick={(event) => props.onChange(event.target.value)}
        //onClick={() => props.formContext.setState({isPaneOpen: true})}
        onClick={() => {
          fetch("https://cltour.uber.space/wp-json/cltour-rest-api/v1/sevdesk?action=importcontactfromsevdesk")
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            alert('importati')
          });
        }}
        //onClick={() => console.log(props.formContext)}
        >Importa</button>
      );
    }
  },
  METE: {
    nav: "main",
    "ui:field": "asyncTypeahead",
    "asyncTypeahead": {
      "id": "mete",
      mapping: "ID",
      "source": "wp_cl_destinations",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      labelKey:"NAME",
      optionsPath: 'data.data',
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=NAME&source=wp_cl_destinations`).then(function (res) {
        return res.json();
      }),
    }
  },
  ADDMETE: {
    "ui:widget": (props) => {
    return (
      <button type="button" className="btn btn-outline-primary form-control"
      //onClick={(event) => props.onChange(event.target.value)}
      //onClick={() => props.formContext.setState({isPaneOpen: true})}
      onClick={() => props.formContext.currentForm.openSlidePanel({ID:'new'}, 'wp_cl_destinations', '1')}
      //onClick={() => console.log(props.formContext)}
      >Aggiungi</button>
    );
  }},
  REFERENTE: {
    nav: "main",
    "ui:field": "asyncTypeahead",
    "asyncTypeahead": {
      "id": "referente",
      mapping: "ID",
      "source": "wp_cl_peoples",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      labelKey: (option) => `${option.FIRSTNAME} ${option.SURNAME} ${option.PHONE} ${option.EMAIL}`,
      //labelKey: "SURNAME",
      optionsPath: 'data.data',
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=SURNAME&source=wp_cl_peoples`).then(function (res) {
        return res.json();
      }),
    }
  },
  ADDREFERENTE: {
    "ui:widget": (props) => {
    return (
      <button type="button" className="btn btn-outline-primary form-control"
      //onClick={(event) => props.onChange(event.target.value)}
      //onClick={() => props.formContext.setState({isPaneOpen: true})}
      onClick={() => props.formContext.currentForm.openSlidePanel({ID:'new'}, 'wp_cl_peoples', '11')}
      //onClick={() => console.log(props.formContext)}
      >Aggiungi</button>
    );
  }},
  MODULIDIDATTICI: {nav: "main"},
  NUMPARTECIPANTIPREV: {nav: "main"},
  GRATUITA: {nav: "main"},
  ACCOMPAGNATORE: {
    nav: "main",
    "ui:field": "asyncTypeahead",
    "ui:forceLabelDisplay": true,
    "asyncTypeahead": {
      "id": "accompagnatore",
      mapping: "ID",
      "multiple": true,
      "source": "wp_cl_peoples",
      "url": "https://cltour.uber.space/wp-json/cltour-rest-api/v1/get",
      placeholder:"Scegli uno o più Accompagnatori",
      labelKey: (option) => `${option.FIRSTNAME} ${option.SURNAME}`,
      //labelKey: "SURNAME",
      //options: [],
      optionsPath: 'data.data',
      search: (url, query) => fetch(`${url}?query=${query}&queryWhere=FIRSTNAME&source=wp_cl_peoples`).then(function (res) {
        return res.json();
      }),
    }
  },
  DEPOSITO: {nav: "main"},
  MEZZO: {nav: "main", "ui:placeholder": "Tipologie di trasporto"},
  //GESTIONEPAGAMENTI: {nav: "main"},
  DETAILS: {
    "ui:widget": "textarea",
    "ui:options": {
      rows: 6
    },
    "ui:placeholder": "ulteriori dettagli del viaggio",
    nav: "main"
  },
  VITTO: {nav: "main"},
  ALLOGGIO: {nav: "main"},
  GESTIONEISCRITTI: {nav: "main"},
  GESTIONEPAGINDIVIDUALI: {nav: "main"},
  ASSICANNULVIAG: {nav: "main"},
  //VIAGGIOSCUOLA: {nav: "main"},
  PUBBLICO: {nav: "main"},
  STATUS: {nav: "main"},
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
  { 'ui:col': { md: 12, children: [
    { 'ui:group': 'Dettagli Viaggio', 'ui:row': [
      { 'ui:col': { md: 12, children: ['DESCRIPTION'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 12, children: ['DETAILS'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['DATAINIZIO'] } },
      { 'ui:col': { md: 6, children: ['DATAFINE'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 4, children: ['ENTE'] } },
      { 'ui:col': { md: 2, children: ['IMPORTENTI'] } },
      { 'ui:col': { md: 4, children: ['REFERENTE'] } },
      { 'ui:col': { md: 2, children: ['ADDREFERENTE'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 4, children: ['METE'] } },
      { 'ui:col': { md: 2, children: ['ADDMETE'] } },
      { 'ui:col': { md: 6, children: ['MODULIDIDATTICI'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 3, children: ['DATARICHIESTA'] } },
      { 'ui:col': { md: 3, children: ['SCADINTERNAPREV'] } },
      { 'ui:col': { md: 6, children: ['NUMPARTECIPANTIPREV'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['GRATUITA'] } },
      { 'ui:col': { md: 6, children: ['ACCOMPAGNATORE'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['TIPOVIAGGIO'] } },
      { 'ui:col': { md: 6, children: ['MEZZO'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 12, children: ['GESTIONEPAGAMENTI'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['VITTO'] } },
      { 'ui:col': { md: 6, children: ['ALLOGGIO'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 3, children: ['GESTIONEISCRITTI'] } },
      { 'ui:col': { md: 3, children: ['GESTIONEPAGINDIVIDUALI'] } },
      { 'ui:col': { md: 3, children: ['PUBBLICO'] } },
      { 'ui:col': { md: 3, children: ['STATUS'] } },
    ] },
  ] } },
] },
}

//TODO: migrare in database
export const  schema_peopletotravels = {
  "title": "Persona in Viaggio",
  "type": "object",
  "definitions": {
    "payments": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string"
        },
        "value": {
          "type": "string"
        },
        "expiration": {
          "type": "string", "format": "date"
        },
      }
    }
  },
  "properties": {
    "source": {"type": "string", "title": "source", "default": "wp_cl_peopletotravels"},
    "procedure": {"type": "string", "title": "procedure", "default": "save_personstotravel"},
    "FIRSTNAME": {"type": "string", "title": "Nome", "readOnly": true},
    "SURNAME": {"type": "string", "title": "Cognome", "readOnly": true},
    "PHONE": {"type": "string", "title": "Telefono"},
    "EMAIL": {"type": "string", "title": "eMail"},
    "BIRTHDATE": {"type": "string", "format": "date", "title": "data di nascita", "readOnly": true},
    "BIRTHPLACE": {"type": "string", "title": "Luogo di Nascita"},
    "IS_STUDENT": {"type": "boolean", "title": "Studente"},
    "STATUS": {"type": "string", "title": "Stato"},
    "PAYMENTS": {
      "type": "array",
      //"title": "A list of strings",
      "items": {
        "$ref": "#/definitions/payments"
      }
    },
  }
}

//TODO: migrare in database
export const  uiSchema_peopletotravels = {
  "source": {"ui:widget": "hidden", "nav": "main", "classNames": "d-none"},
  "procedure": {"ui:widget": "hidden", "nav": "main"},
  "FIRSTNAME": {"nav": "main"},
  "SURNAME": {"nav": "main"},
  "PHONE": {"nav": "main"},
  "EMAIL": {"nav": "main"},
  "BIRTHDATE": {"nav": "main"},
  "BIRTHPLACE": {"nav": "main"},
  "IS_STUDENT": {"nav": "main"},
  "STATUS": {"nav": "main"},
  "PAYMENTS": {
      "nav": "payments",
      "items": {
        "ui:emptyValue": "",
      },
      "ui:options": {
          "orderable": false
      }
  },
  "navConf":{
    "navs": [{
        "nav": "main",
        "name": "General",
        "icon": "fas fa-user-edit"
      },{
        "nav": "payments",
        "name": "Pagamenti",
        "icon": "fas fa-money-bill-wave-alt"
      }]
  }
  /*
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
  { 'ui:col': { md: 12, children: [
    { 'ui:group': 'Dettagli Persona', 'ui:row': [
      { 'ui:col': { md: 6, children: ['FIRSTNAME'] } },
      { 'ui:col': { md: 6, children: ['SURNAME'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['PHONE'] } },
      { 'ui:col': { md: 6, children: ['EMAIL'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['BIRTHDATE'] } },
      { 'ui:col': { md: 6, children: ['BIRTHPLACE'] } },
    ] },
    { 'ui:row': [
      { 'ui:col': { md: 6, children: ['IS_STUDENT'] } },
      { 'ui:col': { md: 6, children: ['STATUS'] } }
    ] }
  ] } },
  ] },
  */
}
