import React from "react";
import { GENERIC_NAV } from "react-jsonschema-form-pagination/lib/utils";

function CustomNavs({ navs: { links }, onNavChange }) {
  let relLinks = links.filter(({ nav }) => nav !== GENERIC_NAV);
  /*
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto nav-tabs">
                {relLinks.map(({ nav, name, icon, isActive }, i) => (
                <li key={i} onClick={() => onNavChange(nav)} className={isActive ? "nav-item active" : "nav-item"}>
                    <a className="nav-link">
                    {icon && <span className={icon} aria-hidden="true"/>}
                    &nbsp;{name || nav}
                    </a>
                </li>
                ))}
            </ul>
        </div>
    </nav>
  );
  */
    return (
        <ul className="nav nav-tabs">
            {relLinks.map(({ nav, name, icon, isActive }, i) => (
            <li key={i} onClick={() => onNavChange(nav)} className={isActive ? "nav-item active" : "nav-item"}>
                <a className="nav-link">
                {icon && <span className={icon} aria-hidden="true"/>}
                &nbsp;{name || nav}
                </a>
            </li>
            ))}
        </ul>
    );
}

export default CustomNavs;