import React, { useState, Fragment } from 'react'
import {Route} from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { LinkContainer } from "react-router-bootstrap"

//import ListComp from './ListComp'
import FormCom from './FormComp'
import ToursDetailsComp from './ToursDetailsComp'
import {API_URL_REST, URLS} from '../_constants/app.constants'

const apiUrlRest = API_URL_REST
const urls = URLS

class ToursComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        key: 'list',
        viaggio_corrente: false,
        modalShow: false
      };
      this.handleSelect = this.handleSelect.bind(this);
      this.handleModalClose = this.handleModalClose.bind(this);
      this.setSelectedTour = this.setSelectedTour.bind(this);
      this.deleteTour = this.deleteTour.bind(this);
    }

    setSelectedTour(row){
      this.setState({viaggio_corrente: row})
    }

    handleSelect(row){
      this.setSelectedTour(row)
      this.props.history.push({
        pathname: '/tours/details',
        state: {el_id:row}
      })
    }

    deleteTour(){
      //alert('qui deleteTour')
      var bodyobj = {deleteproc:'tourdelete', source: 'wp_cl_travels', id: this.state.viaggio_corrente.ID}
      var body = JSON.stringify(bodyobj);

      fetch(apiUrlRest+'delete', {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: body
      })
      // We get the API response and receive data in JSON format...
      .then(response => response.json())
      // ...then we update the users state
      .then(data => {
        var res = data.data     
        this.setState({
          modalShow: false,
          viaggio_corrente: false,
          isLoading: false
        })
        //this.props.refresh()
        this.props.history.push({
          pathname: '/tours/list',
          state: {source:'wp_cl_travels', layout:'13'}
        })
        }
      )
      .catch(error => this.setState({ error, isLoading: false }));
    }

    handleModalClose(){
      this.setState({modalShow: false})
    }

    render() {
      if(!!this.state.viaggio_corrente.BOOKINGFORMLAYOUT) {
        //var formUrl = "https://cltour.uber.space/formbuilder?layoutid="+this.state.viaggio_corrente.BOOKINGFORMLAYOUT+"&travelid="+this.state.viaggio_corrente.ID
        var formUrl = urls.FORMBUILDER_URL+"?layoutid="+this.state.viaggio_corrente.BOOKINGFORMLAYOUT+"&travelid="+this.state.viaggio_corrente.ID
      } else {
        //var formUrl = "https://cltour.uber.space/formbuilder?travelid="+this.state.viaggio_corrente.ID
        var formUrl = urls.FORMBUILDER_URL+"?travelid="+this.state.viaggio_corrente.ID
      }
      /*
      if(this.state.viaggio_corrente.BOOKINGFORMLAYOUT) {
        var formUrl = urls.FORMBUILDER_URL+"?layoutid="+this.state.viaggio_corrente.BOOKINGFORMLAYOUT
      } else {
        var formUrl = urls.FORMBUILDER_URL+"?id="+this.state.viaggio_corrente.ID
      }
      */
      if(this.state.viaggio_corrente.BOOKINGFORMLAYOUT) {
        //var bookingFormUrl = "https://cltour.uber.space/booking?layoutid="+this.state.viaggio_corrente.BOOKINGFORMLAYOUT
        //var bookingFormUrl = false
        var bookingFormUrl = urls.BOOKING_URL+"?layoutid="+this.state.viaggio_corrente.BOOKINGFORMLAYOUT
        var viaggidellamemoriaFormUrl = "https://www.viaggidellamemoria.it/booking?layoutid="+this.state.viaggio_corrente.BOOKINGFORMLAYOUT
      }
      if(this.state.viaggio_corrente.ENCRIPTBOOKINGPARAM) {
        var bookingFormEncriptedUrl = urls.BOOKING_URL+"?sid="+this.state.viaggio_corrente.ENCRIPTBOOKINGPARAM
        var viaggidellamemoriaEncriptedFormUrl = "https://www.viaggidellamemoria.it/booking?sid="+this.state.viaggio_corrente.ENCRIPTBOOKINGPARAM
      }

      return (
        <div>
          <Navbar bg="light" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <LinkContainer to={{
                  pathname: '/tours/list',
                  state: {source:'wp_cl_travels', layout:'13'}}}
                  onClick={(event) => this.setState({viaggio_corrente: false})}
                  >
                  <Nav.Link>Elenco</Nav.Link>
                </LinkContainer>
                {/*
                <LinkContainer to={{
                  pathname: '/tours/details',
                  state: {source:'wp_cl_travels', el_id:'new'}}}
                  onClick={(event) => this.setSelectedTour('new', null, event)}
                  >
                  <Nav.Link>Nuovo</Nav.Link>
                </LinkContainer>
                */}
            </Nav>
            <Navbar.Brand className="mx-auto">{this.state.viaggio_corrente.DESCRIPTION} {this.state.viaggio_corrente.DATAINIZIO}</Navbar.Brand>
            {(!!this.state.viaggio_corrente) ? (
              <div>
                {(bookingFormUrl) ? (
                  /*
                  <Button variant="outline-primary" onClick={(event) => navigator.clipboard.writeText(bookingFormUrl)}>Booking Form</Button>
                  */
                  <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Booking Form">
                    <Dropdown.Header>Booking Solo</Dropdown.Header>
                    <Dropdown.Item onClick={(event) => window.open(bookingFormUrl, "_blank")}>Apri</Dropdown.Item>
                    <Dropdown.Item onClick={(event) => navigator.clipboard.writeText(bookingFormUrl)}>Copia negli Appunti</Dropdown.Item>
                    <Dropdown.Item onClick={(event) => window.open(bookingFormEncriptedUrl, "_blank")}>Apri Link criptato</Dropdown.Item>
                    <Dropdown.Item onClick={(event) => navigator.clipboard.writeText(bookingFormEncriptedUrl)}>Copia Link criptato</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Header>Booking in viaggidellamemoria</Dropdown.Header>
                    <Dropdown.Item onClick={(event) => window.open(viaggidellamemoriaFormUrl, "_blank")}>Apri</Dropdown.Item>
                    <Dropdown.Item onClick={(event) => navigator.clipboard.writeText(viaggidellamemoriaFormUrl)}>Copia negli Appunti</Dropdown.Item>
                    <Dropdown.Item onClick={(event) => window.open(viaggidellamemoriaEncriptedFormUrl, "_blank")}>Apri Link criptato</Dropdown.Item>
                    <Dropdown.Item onClick={(event) => navigator.clipboard.writeText(viaggidellamemoriaEncriptedFormUrl)}>Copia Link criptato</Dropdown.Item>
                  </DropdownButton>
                ) : (<div></div>)}
                <Button variant="outline-primary" onClick={(event) => window.open(formUrl, "_blank")}>Form Builder</Button>
                {/*<Button variant="outline-danger" onClick={(event) => this.setState({modalShow: true})}>Delete</Button>*/}
              </div>
            ) : (
              <div></div>
            )}
          </Navbar.Collapse>
          </Navbar>
          <Modal size="sm" show={this.state.modalShow} onHide={this.handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cancella Viaggio</Modal.Title>
            </Modal.Header>
            <Modal.Body>Vuoi davvero cancellare il viaggio?</Modal.Body>
            <Modal.Footer>
              <Button variant="link" onClick={this.handleModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={this.deleteTour}>
                Cancellalo!
              </Button>
            </Modal.Footer>
          </Modal>
          {/*
          <Route path={`/tours/list`} render={(props) => (<ListComp {...props} handleSelect={this.handleSelect} />)} />
          */}
          <Route path={`/tours/list`} render={(props) => (<FormCom {...props} handleSelect={this.handleSelect} />)} />
          <Route path={`/tours/details`} component={ToursDetailsComp} />
        </div>
      );
    }
  }
  
  //render(<ControlledTabs />);
  export default ToursComp