import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import Form from 'react-bootstrap/Form'
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast'
//import ToastHeader from 'react-bootstrap/ToastHeader'
//import ToastBody from 'react-bootstrap/ToastBody'
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import BootstrapTable from 'react-bootstrap-table-next';
import ReactTable from 'react-table-v6'
//import 'react-table/react-table.css'
import { CSVLink, CSVDownload } from "react-csv";

import { API_URL_REST } from '../_constants/app.constants';

const csv = require('csvtojson')
const apiUrlRest = API_URL_REST;

//colonne del file csv scaricabile
/*
const csvData = [
    ["FIRSTNAME", "SURNAME","BIRTHDATE", "BIRTHPLACE","TAXCODE","NATIONALITY", "EMAIL", "PHONE","IDDOCTYPE","IDDOCNUM","IDDOCLOCATION","IDDOCEXPIRATION","NEED"]
];
*/
const csvData = [
    ["FIRSTNAME","SURNAME","BIRTHDATE","BIRTHPLACE","IDENTITYCODE","CITIZENSHIP",
    "EMAIL","PHONE","IDENTITYDOC","IDENTITYTYPE","IDENTITYSOURCE","IDENTITYEXPIRY",
    "PLACEOFRESIDENCE","ADDRESS","CITY","POSTALCODE","STATE",
    "FOODNEEDS"]
];

export default class ToursPeoplesImportComp extends Component {

    /*
    state = {
        files: [],
        tabledata: [],
        headers: [],
        columns: [{
            dataField: 'id',
            text: 'Tabledata ID'
        },{
            dataField: 'name',
            text: 'Product Name'
        },{
            dataField: 'price',
            text: 'Product Price',
            sort: true
        }]
    }
    */

    constructor(props, context) {
        super(props, context);
        this.state = { 
            isLoading : true,
            isLoadingSPREADSHEETCODE : false,
            isLoadingImportPeopleFromSpreadSheet : false,
            selectedTour: this.props.viaggio_corrente,
            data: false,
            files: false,
            isSaved: false,
            showToast: false,
            error: null,
            tabledata: [],
            headers: [],
            columns: [{
                dataField: 'id',
                text: 'Tabledata ID'
            }],
            //spreadSheetCode:props.viaggiocorrente.SPREADSHEETCODE
        }
        //console.log(props);
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData() {
		fetch(apiUrlRest+'get',
        {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            //body: JSON.stringify(formData)
            body: JSON.stringify({ "source": "wp_cl_travels" })
        })
		  // We get the API response and receive data in JSON format...
		  .then(response => response.json())
		  // ...then we update the state
		  .then(data =>
			 this.setState({
				data: data.data.data,
				isLoading: false,
			 })
		  )
		  // Catch any errors we hit and update the app
		  .catch(error => this.setState({ error, isLoading: false }));
    }

	componentDidMount() {
		this.fetchData();
	}

    /*
    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.data !== this.props.data) {
          this.chart = c3.load({
            data: this.props.data
          });
        }
    }
    */

   componentDidUpdate(prevProps, prevState) {
        //if (this.state.value > prevState.value) {
        //this.render();  
        //}
        //console.log('qui');
        //this.setState({ selectedTour: this.props.viaggio_id })
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        this.setState({
            files: acceptedFiles
        });

        acceptedFiles.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                const fileAsBinaryString = reader.result;

                csv({
                    noheader: false,
                    delimiter: "auto",
                    output: "json"
                })
                .fromString(fileAsBinaryString)
                .on('header',(header)=>{
                    var newheaders = [{ Header: 'Num', accessor: 'Num' }]
                    var newcolumns = [{ dataField: 'Num', text: 'Num', sort: true }]
                    header.map(function(item, i){
                        newheaders.push({
                            Header: item,
                            accessor: item
                        })
                        newcolumns.push({
                            dataField: item,
                            text: item,
                            sort: true
                        })
                    })
                    //columns = tablecolumns
                    //console.log(newcolumns);
                    this.setState({headers: newheaders})
                    this.setState({columns: newcolumns})
                })
                .then((csvRows) => {
                    const toJson = []
                    csvRows.forEach((aCsvRow, i) => {
                        //if (i !== 0) {
                            const builtObject = {Num : i}
                            Object.keys(aCsvRow).forEach((aKey) => {
                                const valueToAddInBuiltObject = aCsvRow[aKey];
                                //const keyToAddInBuiltObject = csvRows[0][aKey];
                                //builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                                builtObject[aKey] = valueToAddInBuiltObject;
                            })
                            toJson.push(builtObject)
                        //}
                    })
                    //console.log(toJson)
                    //tabledata = toJson
                    this.setState({tabledata: toJson})
                })
            };

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');

            reader.readAsBinaryString(file);
        });
    }

    saveBtnClick() {
        if (this.state.selectedTour == false)
            alert('seleziona un viaggio');
        fetch(apiUrlRest+'import',
        {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            //body: JSON.stringify(formData)
            body: JSON.stringify({"Tour": this.state.selectedTour, "PeopleData": this.state.tabledata })
        })
            // We get the API response and receive data in JSON format...
            .then(response => response.json())
            // ...then we update the state
            .then(data => {
                    this.setState({
                        isLoading: false,
                    })
                    //console.log('salvato')
                    this.setState({isSaved: true})
                    this.setShowToast(true)
                }
            )
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    setShowToast(show){
        this.setState({showToast: show})
    }

    spreadSheetCodeSubmit = async (event) => {
        event.preventDefault();
        this.setState({ isLoadingSPREADSHEETCODE: true });
        try {
          const response = await fetch(apiUrlRest+'write', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "source": "wp_cl_travels",
                "ID": this.state.selectedTour.ID, 
                "SPREADSHEETCODE": this.state.selectedTour.SPREADSHEETCODE}),
          });
    
          if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
          }
    
          const data = await response.json();
          console.log('API response:', data);
    
          // Clear the input field (optional)
          //this.setState({ spreadSheetCode: '' });
          this.setState({
            selectedTour: {
              ...this.state.selectedTour,
              SPREADSHEETCODE: data.data.SPREADSHEETCODE,
            },
          })
          this.setState({ isLoadingSPREADSHEETCODE: false });
        } catch (error) {
          console.error('Error sending data to PHP API:', error);
        }
    };

    importPeopleFromSpreadSheetSubmit = async (event) => {
        event.preventDefault();
        this.setState({ isLoadingImportPeopleFromSpreadSheet: true });
    
        const { selectedTour } = this.state;
        const sheetid = selectedTour.SPREADSHEETCODE;
        const travelid = selectedTour.ID;
    
        try {
          const response = await fetch(apiUrlRest+'google', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sheetid, travelid }),
          });
    
          if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
          }
    
          const data = await response.json();
          console.log('API response:', data);
          if(data.status == 500){
            this.setState({ error: data.message, isLoading: false });
          } else {
            this.setState({ isSaved: true, isLoading: false });
          }
        } catch (error) {
          console.error('Error calling WordPress API:', error);
          this.setState({ error: error.message, isLoading: false });
        } finally {
          this.setState({ isLoadingImportPeopleFromSpreadSheet: false });
          this.setShowToast(true)
        }
    }

    render() {
        const { isLoading, data, error } = this.state;

        //per il toast
        //const [show, setShow] = useState(false);

		if (!isLoading && data !== undefined) {
            return (
                <div>
                    <h2>Importa da CSV</h2>
                    <CSVLink data={csvData} className="btn btn-primary float-right" filename={"cl_persone_in_viaggio.csv"} target="_blank" separator={";"}>File da Compilare</CSVLink>
                    <Form.Group controlId="peoplesImport.ControlSelect1">
                        {/*
                        <Form.Label>Scegli il viaggio</Form.Label>
                        */}
                        <Form.Control disabled as="select" onChange={event => this.setState({selectedTour: event.target.value})} value={this.state.selectedTour}>
                        <option value="0">Scegli un viaggio</option>
                        {data.map(record => {
                            return (
                                <option key={record.ID} value={record.ID}>
                                    {record.DESCRIPTION} - {record.DATAINIZIO}
                                </option>
                                )
                            })
                        }
                        </Form.Control>
                    </Form.Group>
                    <Dropzone onDrop={this.onDrop.bind(this)} multiple={false}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p>trascina qui il file oppure clicca per selezionarlo</p>
                        </div>
                        </section>
                    )}
                    </Dropzone>
                    <ReactTable
                        data={this.state.tabledata}
                        columns={this.state.headers}
                        sortable={true}
                        resolveData={data => data.map(row => row)} // But you can break immutability here because `resolveData` runs when the `data` prop changes!
                    />
                    {this.state.tabledata.length > 0 ?
                    <button type="button" className="btn-floating" onClick={event => this.saveBtnClick()}><i className="far fa-save"></i></button>
                     : null}
                    {/*
                    <BootstrapTable
                        keyField='id'
                        data={this.state.tabledata}
                        columns={this.state.columns}
                        loading='true' />
                    */}
                    {/*this.state.isSaved == true ?
                    <Toast style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'green'
                    }} onClose={() => this.setShowToast(false)} show={this.state.showToast} delay={3000} autohide>
                        <Toast.Header>
                            <img src="https://via.placeholder.com/20x20?text=OK" className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Salvato</strong>
                        </Toast.Header>
                        <Toast.Body>I dati sono stati salvati</Toast.Body>
                    </Toast>
                    :  null*/}
                    <hr/>
                    <h2>Importa da Google Spread Sheet</h2>
                    <InputGroup className="mb-3">
                        <FormControl
                        placeholder="Google Spread Sheet Code"
                        aria-label="Google Spread Sheet Code"
                        aria-describedby="Google Spread Sheet Code"
                        value={this.state.selectedTour.SPREADSHEETCODE}
                        onChange={(event) => this.setState({
                            selectedTour: {
                              ...this.state.selectedTour,
                              SPREADSHEETCODE: event.target.value,
                            },
                          })}
                        />
                        <InputGroup.Append>
                        <Button variant="outline-primary" onClick={this.spreadSheetCodeSubmit}>
                            {this.state.isLoadingSPREADSHEETCODE ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                                'Salva'
                            )}
                        </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <Button variant="primary" size="lg" block onClick={this.importPeopleFromSpreadSheetSubmit}>
                    {this.state.isLoadingImportPeopleFromSpreadSheet ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                            'Importa da Google Spread Sheet'
                        )}
                    </Button>

                    {/* Toast for Success or Error Message */}
                    <Toast
                    style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 999
                    }}
                    onClose={() => this.setState({ showToast: false })}
                    show={this.state.showToast}
                    delay={3000}
                    autohide
                    className={ // Conditionally assign class based on state
                        this.state.isSaved ? 'bg-success' :
                        this.state.error ? 'bg-danger' : ''
                    }
                    >
                    {this.state.isSaved ? (
                        <>
                        <Toast.Header>
                            <img src="https://via.placeholder.com/20x20?text=OK" className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Salvato</strong>
                        </Toast.Header>
                        <Toast.Body>I dati sono stati salvati</Toast.Body>
                        </>
                    ) : error ? (
                        <>
                        <Toast.Header>
                            <img src="https://via.placeholder.com/20x20?text=X" className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Errore</strong>
                        </Toast.Header>
                        <Toast.Body>{error}</Toast.Body>
                        </>
                    ) : null}
                    </Toast>
                </div>
            );
		} else {
            return (
				<div className="mx-auto">
					<Spinner className="mx-auto" animation="grow" variant="info" />
				</div>
			)
        }
    }
}