import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
//import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

import Spinner from 'react-bootstrap/Spinner';
//import Button from 'react-bootstrap/Button'
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
//import Popover from 'react-bootstrap/Popover'
//import ListGroup from 'react-bootstrap/ListGroup'

import { API_URL_REST } from '../_constants/app.constants';

const apiUrlRest = API_URL_REST;

/*
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;
*/

const expandRow = {
    renderer: (row, rowIndex) => (
      <div>
        <p>{ `This Expand row is belong to rowKey ${row.id}` }</p>
        <p>You can render anything here, also you can add additional data on every row object</p>
        <p>expandRow.renderer callback will pass the origin row object to you</p>
      </div>
    )
  };

class TableComp extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = { 
            isLoading : true,
            source : '',
            where: '',
            tabledata: [],
            headers: [],
            rowMenuShow: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.renderEditable = this.renderEditable.bind(this);
        this.onChangeFct = this.onChangeFct.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

	componentDidMount() {
        if (typeof this.props.location != 'undefined'){
            if (typeof this.props.location.state != 'undefined'){
                this.setState({source: this.props.location.state.source}, () => {
                    this.fetchData();
                })
            }
        } else {
            this.setState({source: this.props.source, where: this.props.where}, () => {
                this.fetchData();
            })
        }
        //this.fetchData();
	}

    /*
    static getDerivedStateFromProps() {

    }
    */
   
    componentDidUpdate(prevProps, prevState) {
        //if (this.state.value > prevState.value) {
            //this.render();  
        //}
    }

    setRowMenuShow(show){
        this.setState({rowMenuShow: show})
    }

    fetchData() {
		fetch(apiUrlRest+'get',
        {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            //body: JSON.stringify(formData)
            body: JSON.stringify({ "source": this.state.source, "where": this.state.where })
        })
		  // We get the API response and receive data in JSON format...
		  .then(response => response.json())
		  // ...then we update the state
		  .then(data => {
            //var newheaders = [{ Header: 'Num', accessor: 'Num' }]
            var newheaders = []
 
            if(typeof this.props.actionbtn != 'undefined' && this.props.actionbtn == true) {
                newheaders.push({
                    Header: () => (
                        <span onClick={this.props.openSlidePanel}>
                          <i className="fas fa-plus-square" />
                        </span>
                    ),
                    filterable: false,

                    Cell: row => (
                        <div>
                            {/* 
                            <button className="btn btn-danger" onClick={() => this.handleDelete(row.original)}><i className="fas fa-trash" /></button>
                            */}
                            {/*console.log(row)*/}
                            {/* 
                            <OverlayTrigger
                                key={row.index}
                                placement='right'
                                trigger={['hover', 'focus','click']}
                                overlay={
                                    <Popover id={`popover-row-${row.index}`}>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>Cras justo odio</ListGroup.Item>
                                            <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                                            <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                                            <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                                        </ListGroup>
                                    </Popover>
                                  }
                                >
                                <Button variant="link"><i className="fas fa-ellipsis-v" /></Button>
                            </OverlayTrigger>
                            
                            <Button variant="link" onClick={() => this.props.openSlidePanel(row.row)}><i className="fas fa-ellipsis-v" /></Button>
                            */}
                        </div>
                    )
                })
            }

            data.data.columns.map(function(item, i){
                newheaders.push({
                    dataField: item,
                    text: item,
                    //Header: item,
                    //accessor: item,
                    //Cell: this.renderEditable()
                    //render: props => <input value={props.row.name} onChange={this.onChangeFct} />
                    //...this.editableColumnProps
                })
            })

            /*
            newheaders = [{
                dataField: 'id',
                text: 'Product ID'
              }, {
                dataField: 'name',
                text: 'Product Name'
              }, {
                dataField: 'price',
                text: 'Product Price'
            }]
            */

            //console.log(newheaders);
             this.setState({
                //tabledata: Array.from(data.data.data.table),
                tabledata: data.data.data,
                headers: newheaders,
				isLoading: false,
             })
            }
		  )
		  // Catch any errors we hit and update the app
		  .catch(error => this.setState({ error, isLoading: false }));
    }

    onChangeFct = () => console.log("onChange usually handled by redux");

    handleEdit = () => console.log("handleEdit usually handled by redux");
    handleDelete(row) {
        console.log("handleDelete usually handled by redux "+JSON.stringify(row, null, 4));
    }

    renderEditable(cellInfo) {
        return (
          <div
            style={{ backgroundColor: "#fafafa" }}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
              const data = [...this.state.tabledata];
              data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
              this.setState({ tabledata: data });
            }}
            dangerouslySetInnerHTML={{
              __html: this.state.data[cellInfo.index][cellInfo.column.id]
            }}
          />
        );
    }
    
    render() {
        //const { isLoading, formSchema, formUiSchema, formData } = this.state;
        const {isLoading} = this.state;
        if (isLoading !== true) {
           
        return (
        /*
        <ToolkitProvider
            keyField="id"
            data={this.state.tabledata}
            columns={this.state.headers}
            search
            expandRow={ expandRow }
            style={{
                height: "calc(100vh - 100px)" // This will force the table body to overflow and scroll, since there is not enough room
            }}
          >  {
            props => (
              <div>
                <h3>Input something at below input field:</h3>
                <SearchBar { ...props.searchProps } />
                <ClearSearchButton { ...props.searchProps } />
                <hr />
                <BootstrapTable
                  { ...props.baseProps }
                />
                <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton>
              </div>
            )
          }
        </ToolkitProvider>
        */
        <BootstrapTable
            keyField="id"
            data={this.state.tabledata}
            columns={this.state.headers}
            expandRow={ expandRow }
            style={{
                height: "calc(100vh - 100px)" // This will force the table body to overflow and scroll, since there is not enough room
            }}
        />
        );
        } else {
            return (
                <div className="mx-auto">
                    <Spinner className="mx-auto" animation="grow" variant="info" />
                </div>
            )
        }
      }

}

export default TableComp
