import React from 'react'
import { render } from 'react-dom'
//import LayoutField from '../../src'
import FormCom from './FormComp'
//import ImageUploadWidget from './ImageUploadWidget'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

//import './dnd.less'

export const TitleField = (props) => {
  console.log('titlefield', props)
  const { title, required, id } = props

  //let legend = legend = `${title}${(required ? '*' : '')}`
  let legend = 'mah';
  return <label className="control-label" for={id}>{legend}</label>
}

let schema = {
  type: "object",
  properties: {
    str: { type: "string" },
    lab: { title: "This is a test label" },
    simpleTable: {
      type: "array",
      items: {
        type: "object",
        properties: {
          str: { type: "string" },
          dateTime: { type: "string", format: "date-time" },
        },
      },
    },
  },
};

let schemaProducts = {
  type: "object",
  properties: {
    str: { type: "string" },
    lab: { title: "This is a test label" },
    simpleTable: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: { type: "string" },
          name: { type: "string" },
          price: { type: "number" },
        },
      },
    },
  },
};

let uiSchema = {
  lab: {
    "ui:field": "simpleLabel",
  },
  simpleTable: {
    "ui:field": "table",
    table: {
      tableCols: [
        {
          dataField: "dateTime",
          dataFormat: "YYYY-MM-DD",

          field: "dateTimePicker",
        },
        {
          editable: false,
          dataField: "str",
          columnCustomFormat:
            '{"function":{"arguments":"cell,row,schema","body":"return \'<a >\'+cell+\'</a>\' "}}', //eslint-disable-line
        },
      ],
      selectRow: {
        mode: "checkbox",
        clickToSelect: true,
        bgColor: "grey",
        onSelectRow: { fieldToUpdate: "picked" },
        onSelectAllRow: { fieldToUpdate: "picked" },
      },
    },
  },
};

let uiSchemaProducts = {
  lab: {
    "ui:field": "simpleLabel",
  },
  simpleTable: {
    "ui:field": "table",
    table: {
      tableCols: [
        {
          dataField: "id",
        },
        {
          editable: false,
          dataField: "name",
        },
        {
          dataField: "price",
          footerData: [
          [
          {
            label: 'Total',
            columnIndex: 0,
            align: 'right',
            formatter: 'columnTotal'
          }
          ]
          ]
        },
      ],
      footerData: [
        [
          {
            label: 'Total',
            columnIndex: 2,
            align: 'right',
            formatter: 'columnTotal'
          },
          {
            label: 'mah',
            columnIndex: 1
          },
        ]
      ],
      selectRow: {
        mode: "checkbox",
        clickToSelect: true,
        bgColor: "grey",
        onSelectRow: { fieldToUpdate: "picked" },
        onSelectAllRow: { fieldToUpdate: "picked" },
      },
    },
  },
};

const products = [];

function addProducts(quantity) {
  const startId = products.length;
  for (let i = 0; i < quantity; i++) {
    const id = startId + i;
    products.push({
      id: id,
      name: 'Item name ' + id,
      price: 2100 + i
    });
  }
}

addProducts(70);

const cellEditProp = {
  mode: 'click'
};

class FooterTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const footerData = [
      [
        {
          label: 'Total',
          columnIndex: 0
        },
        {
          label: 'Total value',
          columnIndex: 2,
          align: 'right',
          formatter: (tableData) => {
            let label = 0;
            for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
              label += parseFloat(tableData[i].price)
            }
            return (
              <strong>{ label }</strong>
            );
          }
        }
      ]
    ];

    return (
      <div>
        <BootstrapTable
          data={ products }
          footerData={ footerData }
          footer
          cellEdit={ cellEditProp }
          pagination
          search>
          <TableHeaderColumn dataField='id' isKey={ true }>Product ID</TableHeaderColumn>
          <TableHeaderColumn dataField='name' footer='Footer 1'>Product Name</TableHeaderColumn>
          <TableHeaderColumn dataField='price'>Product Price</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

class MyForm extends React.Component {
  onSubmit(e) {
    console.log('onSubmit', e)
  }
  render() {
    //const { formData } = this.props
    const formData = {simpleTable: products}

    return (
      <div className='container'>
        <div className="row">
            <div className="col-xs-8 col-xs-offset-2">
              <FormCom
                formData={formData}
                schema={schemaProducts}
                uiSchema={uiSchemaProducts}
                footer
                onSubmit={this.onSubmit}
                noHtml5Validate={true}/>
            </div>
        </div>
      </div>
    )
  }
}

class Demo extends React.Component {
  render() {
    return (
      <div>
        <h3>Demo form</h3>
        <MyForm formData={{
          simpleTable: [
            { str: "Some1", dateTime: new Date().toISOString() },
            { str: "Some2", dateTime: new Date().toISOString() },
            { str: "Some3", dateTime: new Date().toISOString() },
          ],
        }} />
        <hr />
        {/*
        <FooterTable />
        */}
      </div>
    )
  }
}

export default Demo