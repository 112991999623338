import React, { useState, Fragment } from 'react'

//import NavbarComp from './NavbarComp'
//import TablelistComp from './TablelistComp'
import FormComp from './FormComp'

class TourPeopleAttributesComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
      };
      this.handleSelect = this.handleSelect.bind(this);
    }
  
    handleSelect(key, persona_id) {
    }

    render() {
      return (
        <FormComp {...this.props}></FormComp>
      );
    }
  }
  
  //render(<ControlledTabs />);
  export default TourPeopleAttributesComp