import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { connect } from 'react-redux';

import './SideBar.css'

import { history } from '../_helpers';
import { userActions } from '../_actions';

var admin = false;

class SideBar extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        layout_corrente: false
      };
      let user = JSON.parse(localStorage.getItem('user'));
      
      if (!!user && user.hasOwnProperty('record')) {
          admin = user.record.roles.includes("administrator");
      }
    }

	componentDidMount() {
		//this.fetchData();
	}

    render() {
        /*
        if (!!this.props.authentication.loggedIn) {
            admin = this.props.authentication.user.record.roles.includes("administrator");
        }
        */
      return (
        <SideNav
            onSelect={(selected) => {
                //alert(selected);
                if (selected == 'home') {
                    //history.push('/');
                    history.push({
                        pathname: '/',
                        state: {source: {type: 'proc', proc:'timetable'}, layout:'15', layoutForm:'16'}
                        //state: {source: {type: 'proc', proc:'timetable'}, layout:'15', layoutForm:'16', panelSource:{type: 'proc', proc:'timetableDetails'}}
                    })
                }
                if (selected == 'logout') {
                    //dispatch(userActions.logout());
                    userActions.logout();
                }
                if (selected == 'quotations') {
                    history.push({
                        pathname: '/quotations/list',
                        state: {source:'wp_cl_quotations', layout:'12'}
                    })
                }
                if (selected == 'tours') {
                    history.push({
                        pathname: '/tours/list',
                        //state: {source:'wp_cl_travels', layout:'13'}
                        state: {source: {type: 'proc', proc:'getTravels'}, layout:'13'}
                    })
                }
                if (selected == 'peoples') {
                    history.push({
                        pathname: '/peoples/list',
                        state: {source:'wp_cl_peoples', layout:'10', layoutForm:'11'}
                    })
                }
                if (selected == 'companies') {
                    //history.push('/companies/tablelist');
                    history.push({
                        pathname: '/companies/tablelist',
                        state: {source:'wp_cl_companies', layout:'4', layoutForm:'3'}
                    })
                }
                if (selected == 'destinations') {
                    history.push({
                        pathname: '/destinations/tablelist',
                        state: {source:'wp_cl_destinations', layout:'2', layoutForm:'1'}
                        //state: {source:'wp_cl_destinations', sourceNew:{layout:'2'}, layoutForm:'1'}
                    })
                }
                if (selected == 'settings/tourattributes') {
                    history.push({
                        pathname: '/tourattributes',
                        state: {source:'wp_cl_tourattributes', layout:'17'}
                    })
                }
                if (selected == 'test') {
                    history.push('/test');
                }
                if (selected == 'dev/layouts') {
                    history.push({
                        pathname: '/layouts/tablelist',
                        state: {source:'wp_cl_layouts'}
                    })
                }
                if (selected == 'dev/procedures') {
                    history.push({
                        pathname: '/procedures/tablelist',
                        state: {source:'wp_cl_procedures'}
                    })
                }
                if (selected == 'dev/routes') {
                    history.push({
                        pathname: '/routes/tablelist',
                        state: {source:'wp_cl_routes'}
                    })
                }
            }}
        >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected="home">
                <NavItem eventKey="home">
                    <NavIcon>
                        <i className="fas fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Home
                    </NavText>
                </NavItem>
                <NavItem eventKey="quotations">
                    <NavIcon>
                        <i className="fas fa-fw fa-file-alt" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Preventivi
                    </NavText>
                </NavItem>
                <NavItem eventKey="tours">
                    <NavIcon>
                        <i className="fas fa-fw fa-directions" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Viaggi
                    </NavText>
                </NavItem>
                <NavItem eventKey="peoples">
                    <NavIcon>
                        <i className="fas fa-fw fa-users" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Persone
                    </NavText>
                </NavItem>
                <NavItem eventKey="companies">
                    <NavIcon>
                        <i className="fas fa-fw fa-building" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Clienti/Fornitori
                    </NavText>
                </NavItem>
                <NavItem eventKey="destinations">
                    <NavIcon>
                        <i className="fas fa-fw fa-map-marker-alt" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Destinazioni
                    </NavText>
                </NavItem>
                <NavItem eventKey="settings">
                    <NavIcon>
                        <i className="fa fa-fw fa-cogs" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
                    </NavIcon>
                    <NavText style={{ paddingRight: 32 }} title="Settings">
                        Settings
                    </NavText>
                    <NavItem eventKey="settings/tourattributes">
                        <NavText title="Tour Attributes">
                            Attributi Viaggio
                        </NavText>
                    </NavItem>
                </NavItem>
                <NavItem eventKey="test" navitemClassName={!admin ? 'd-none' : null}>
                    <NavIcon>
                        <i className="fas fa-fw fa-remove-format" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Test
                    </NavText>
                </NavItem>
                {/*
                <NavItem eventKey="settings" navitemClassName={!admin ? 'd-none' : null}>
                    <NavIcon>
                        <i className="fa fa-fw fa-cogs" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
                    </NavIcon>
                    <NavText style={{ paddingRight: 32 }} title="Settings">
                        Settings
                    </NavText>
                    <NavItem eventKey="settings/policies">
                        <NavText title="Policies">
                            Policies
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="settings/network">
                        <NavText title="Network">
                            Network
                        </NavText>
                    </NavItem>
                </NavItem>
                */}
                <NavItem eventKey="dev" navitemClassName={!admin ? 'd-none' : null}>
                    <NavIcon>
                        <i className="fa fa-fw fa-vial" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
                    </NavIcon>
                    <NavText style={{ paddingRight: 32 }} title="Development">
                        Development
                    </NavText>
                    <NavItem eventKey="dev/layouts">
                        <NavText title="Layouts">
                            Layouts
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="dev/procedures">
                        <NavText title="Procedures">
                            Procedures
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="dev/routes">
                        <NavText title="Menu">
                            Menu
                        </NavText>
                    </NavItem>
                </NavItem>
                {/* 
                <NavItem eventKey="charts">
                    <NavIcon>
                        <i className="fas fa-fw fa-chart-line" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Charts
                    </NavText>
                    <NavItem eventKey="charts/linechart">
                        <NavText>
                            Line Chart
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="charts/barchart">
                        <NavText>
                            Bar Chart
                        </NavText>
                    </NavItem>
                </NavItem>
                */}
                <NavItem eventKey="logout">
                    <NavIcon>
                        <i className="fas fa-fw fa-sign-out-alt" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        LogOut
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>
    );
    }
}

const mapStateToProps = state => ({
    ...state
});

//export default SideBar;
export default connect(mapStateToProps)(SideBar);
//export default withRouter(connect(mapStateToProps)(SideBar));
