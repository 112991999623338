import React from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { history } from '../_helpers';

//import Components from "../_helpers/components"
//import TableCom from '../components/TableComp'
import FormCom from './FormComp'
import FormPaymentsComp from './FormPaymentsComp'
//import TablelistComp from './TablelistComp'
import ToursPeoplesImportComp from './ToursPeoplesImportComp'
import TourActions from './TourActions'

const KeysToComponentMap = {
  tab: Tab,
  formcomp: FormCom
}
//array for manage tabs with ACL -- not used yet
const tabs = [
  {
    component: 'tab',
    eventKey:'mailSettings',
    title:'Mail Settings',
    children: [
        {
          component: 'formcomp',
          layout:'43',
          source:"wp_cl_travels"
        }
      ]
    }
]

function renderer(config, props = {}) {
  let currentProps = {...props}
  if (typeof KeysToComponentMap[config.component] !== "undefined") {
    Object.entries(config).forEach(([elkey,value],i) => {
      if(elkey !== 'component' && elkey !== 'children') {
        // This is to satisfy React warning that children should have a key
        currentProps.key = Date.now()+'.'+Math.random(1000)
        currentProps[elkey] = value  
      }
    })
    return React.createElement(
      KeysToComponentMap[config.component],
      currentProps,
      config.children &&
        (typeof config.children === "string"
          ? config.children
          : config.children.map(c => renderer(c, props)))
    )
  }
}

class ToursDetailsComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        key: 'details',
        viaggio_corrente: this.props.location.state.el_id,
        //viaggio_corrente: '',
        row_current: '',
        isPaneOpen: false,
      };
      this.handleSelect = this.handleSelect.bind(this);
      this.openSlidePanel = this.openSlidePanel.bind(this);
      if(this.state.viaggio_corrente == false) {
        history.push({
          pathname: '/tours/list',
          state: {source:'wp_cl_travels'}
        })
      }
    }
  
    componentDidMount() {
      Modal.setAppElement(this.el);
      //this.setState({viaggio_corrente: this.props.location.state.el_id})
    }

    //shouldComponentUpdate() {return false}
    componentDidUpdate(prevProps, prevState, snapshot) {
      //console.log('qui componentDidUpdate');
      if (this.props.location.state.el_id !== prevProps.location.state.el_id) {
        this.setState({viaggio_corrente: this.props.location.state.el_id});
      }
    }
    
    componentWillUnmount() {}

    openSlidePanel(row){
      this.setState({isPaneOpen: true})
      this.setState({row_current: row})
    }

    handleSelect(key, viaggio_id) {
      //alert('selected ' + key);
      //this.setState({key: key, viaggio_corrente: viaggio_id })
      this.setState({key: key})
    }

    render() {
      const tablewhere = "TK_TRAVELS = "+this.props.location.state.el_id['ID'];
      return (
        <div ref={ref => this.el = ref}>
        <Tabs
          id="controlled-tab-tour"
          activeKey={this.state.key}
          onSelect={key => this.setState({ key })}
          mountOnEnter={true}
        >
          <Tab eventKey="details" title="Dettaglio">
            <FormCom el_id={this.props.location.state.el_id} source="wp_cl_travels"/>
          </Tab>
          <Tab eventKey="tourattributes" title="Attributi Viaggio">
            <FormCom el_id={this.props.location.state.el_id} source={{type:'proc', proc:'attributesTour', table:'wp_cl_travels'}} layout='20'/>
          </Tab>
          <Tab eventKey="payments" title="Pagamenti" >
            <FormPaymentsComp el_id={this.props.location.state.el_id} sourceNew="travel_costs"/>
            {/*<GridComp />*/}
            {/*<button onClick={()=>this.handleSelect("details")}>Go to tab Details</button>*/}
          </Tab>
          <Tab eventKey="peoples" title="Persone" >
            {/*
            <FormCom el_id={this.props.location.state.el_id} source={{type:'proc', proc:'peoplesInTour'}} where={tablewhere} sourceNew={{layout:'21'}} layoutForm='18' panelSource={{proc:'peopleInTour'}}/>
            */}
            <FormCom el_id={this.props.location.state.el_id} source={{type:'proc', proc:'peoplesInTour'}} layout='21' where={tablewhere} layoutForm='18' panelSource={{type: 'proc', proc:'peopleInTour'}}/>
            {/*<TablelistComp source='wp_cl_peopletotravels right join wp_cl_peoples on wp_cl_peoples.ID = wp_cl_peopletotravels.TK_PEOPLE' where={tablewhere} actionbtn={true} openSlidePanel={this.openSlidePanel} />*/}
            {/*<button onClick={()=>this.handleSelect("details")}>Go to tab Details</button>*/}
          </Tab>
          <Tab eventKey="companies" title="Fornitori" >
            {/*<TableCom el_id={this.props.location.state.el_id} source='wp_cl_companiestotravels' sourceNew={{layout:'5'}} layoutForm='6' panelBlock='table' panelProc="TourCliForn"/>*/}
            <FormCom el_id={this.props.location.state.el_id} source='wp_cl_companiestotravels' sourceNew={{layout:'5'}} layoutForm='6' TK="TK_TRAVELS" GROUPBY="TK_COMPANIES"/>
            {/*<button onClick={()=>this.handleSelect("details")}>Go to tab Details</button>*/}
          </Tab>
          <Tab eventKey="clientdeadlines" title="Scadenze Viaggio" >
            {/*<TableCom el_id={this.props.location.state.el_id} source='wp_cl_companiestotravels' sourceNew={{layout:'5'}} layoutForm='6' panelBlock='table' panelProc="TourCliForn"/>*/}
            <FormCom el_id={this.props.location.state.el_id} source='wp_cl_traveldeadlines' sourceNew={{layout:'23'}} layoutForm='24' TK="TK_TRAVELS"/>
            {/*<button onClick={()=>this.handleSelect("details")}>Go to tab Details</button>*/}
          </Tab>
          <Tab eventKey="import" title="Importa Persone" >
            <ToursPeoplesImportComp viaggio_corrente={this.state.viaggio_corrente}/>
          </Tab>
          {tabs.map(config => renderer(config, {el_id:this.props.location.state.el_id}))}
          <Tab eventKey="actions" title="Azioni" >
            <TourActions viaggio_corrente={this.state.viaggio_corrente}/>
          </Tab>
        </Tabs>
        <SlidingPane
            className='some-custom-class'
            overlayClassName='some-custom-overlay-class'
            isOpen={ this.state.isPaneOpen }
            title='Dettaglio'
            subtitle='modifica i dettagli'
            onRequestClose={ () => {
                // triggered on "<" on left top click or on outside click
                this.setState({ isPaneOpen: false });
            } }>
            {/*this.state.row_current*/}
            <FormCom el_id={this.state.row_current.ID} source={{type:'proc', proc:'peopleInTour'}} layout='18'/>
        </SlidingPane>
        </div>
      );
    }
  }
  
  //render(<ControlledTabs />);
  export default ToursDetailsComp