export function getFromObj(obj, key) {
    return key.split(".").reduce(function(o, x) {
        return (typeof o == "undefined" || o === null) ? o : o[x];
    }, obj);
}

//ALERT: in some case not work
//TODO: try to deprecate this objHas and rename hasOwnPropertiesRecursively to objHas
export function objHas(obj, key) {
    return key.split(".").every(function(x) {
        if(typeof obj != "object" || obj === null || ! x in obj)
            return false;
        obj = obj[x];
        return true;
    });
}

//TODO: try to deprecate this objHas and rename hasOwnPropertiesRecursively to objHas
//example: 
//var nestedObj = {planet: {earth: {'@continent': 'africa'}}};
//hasOwnProperties(nestedObj, 'planet.earth.@continent') //returns true
//hasOwnProperties(nestedObj, 'planet.earth.@continent', 'africa') //returns true
//hasOwnProperties(nestedObj, 'planet.earth.unicorns') //returns false
export function hasOwnPropertiesRecursively (target, path, value){
    if (typeof target !== 'object' || target === null) { return false; }

    var parts = path.split('.');

    while(parts.length) {
      var property = parts.shift();
      if (!(target.hasOwnProperty(property))) {
        return false;
      }
      target = target[property];
    }

    if(value){
      return target === value;
    }else{
      return true;
    }
  }