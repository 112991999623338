import React, { useState, Fragment } from 'react'
import {Route} from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { LinkContainer } from "react-router-bootstrap";

//import ListComp from './ListComp'
import FormCom from './FormComp'
import QuotationsDetailsComp from './QuotationsDetailsComp'
import {API_URL_REST} from '../_constants/app.constants';

const apiUrlRest = API_URL_REST;

class QuotationsComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        key: 'list',
        viaggio_corrente: false,
        tour_name: '',
        modalConfShow: false,
        modalDeleteShow: false
      };
      this.handleSelect = this.handleSelect.bind(this);
      this.handleModalClose = this.handleModalClose.bind(this);
      this.setSelectedTour = this.setSelectedTour.bind(this);
      this.confirmTour = this.confirmTour.bind(this);
      this.deleteTour = this.deleteTour.bind(this);
    }

    setSelectedTour(row){
      this.setState({viaggio_corrente: row})
    }

    handleSelect(row){
      this.setSelectedTour(row)
      this.props.history.push({
        pathname: '/quotations/details',
        state: {el_id:row}
      })
    }

    confirmTour(){
      var {viaggio_corrente} = this.state
      if(typeof viaggio_corrente === 'object' && viaggio_corrente !== null) {
        viaggio_corrente = viaggio_corrente.ID
      }
      var bodyobj = {source: 'wp_cl_quotations', id: viaggio_corrente, 'proc': 'confermaPreventivo'}
      var body = JSON.stringify(bodyobj);

      fetch(apiUrlRest+'proc', {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: body
      })
      // We get the API response and receive data in JSON format...
      .then(response => response.json())
      // ...then we update the users state
      .then(data => {
        var res = data.data
        //alert(data.code)
        this.setState({
          modalConfShow: false,
          tour_name: '',
          viaggio_corrente: false,
          isLoading: false
        })
        
        if(data.data.result.code == 400) {
          alert(data.data.result.message)
        } else {
          //this.props.refresh()
          this.props.history.push({
            pathname: '/tours/list',
            state: {source:'wp_cl_travels', layout:'13'}
          })
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
    }

    deleteTour(){
      //alert('qui deleteTour')
      var {viaggio_corrente} = this.state
      if(typeof viaggio_corrente === 'object' && viaggio_corrente !== null) {
        viaggio_corrente = viaggio_corrente.ID
      }

      var bodyobj = {source: 'wp_cl_quotations', id: viaggio_corrente}
      var body = JSON.stringify(bodyobj);

      fetch(apiUrlRest+'delete', {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: body
      })
      // We get the API response and receive data in JSON format...
      .then(response => response.json())
      // ...then we update the users state
      .then(data => {
        var res = data.data     
        this.setState({
          modalDeleteShow: false,
          tour_name: '',
          viaggio_corrente: false,
          isLoading: false
        })
        //this.props.refresh()
        this.props.history.push({
          pathname: '/quotations/list',
          state: {source:'wp_cl_quotations', layout:'12'}
        })
        }
      )
      .catch(error => this.setState({ error, isLoading: false }));
    }

    handleModalClose(){
      this.setState({
        modalConfShow: false,
        modalDeleteShow: false,
      })
    }

    callChildSave(){
      this.refs.child.onSubmit()
    }

    render() {
      var {viaggio_corrente} = this.state
      return (
        <div>
          <Navbar bg="light" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <LinkContainer to={{
                  pathname: '/quotations/list',
                  state: {source:'wp_cl_quotations', layout:'12'}}}
                  onClick={(event) => this.setState({viaggio_corrente: false})}
                  >
                  <Nav.Link>Elenco</Nav.Link>
                </LinkContainer>
                <LinkContainer to={{
                  pathname: '/quotations/details',
                  state: {source:'wp_cl_quotations', el_id:'new'}}}
                  onClick={(event) => this.setSelectedTour('new', null, event)}
                  >
                  <Nav.Link>Nuovo</Nav.Link>
                </LinkContainer>
            </Nav>
            <Navbar.Brand className="mx-auto">{this.state.viaggio_corrente.DESCRIPTION} {this.state.viaggio_corrente.DATAINIZIO}</Navbar.Brand>
              {(!!viaggio_corrente && viaggio_corrente != 'new' && viaggio_corrente.STATUS != 'Confermato') ? (
                <ButtonGroup aria-label="Actions group">
                {/*
                <Button variant="outline-primary" onClick={this.callChildSave}>Salva</Button>   
                */}
                <Button variant="outline-success" onClick={(event) => this.setState({modalConfShow: true})} {...(viaggio_corrente.DONE && { disabled: true })}>Confermato</Button>
                <Button variant="outline-danger" onClick={(event) => this.setState({modalDeleteShow: true})}>Delete</Button>
                </ButtonGroup>
              ) : (
                <></>
              )}
              {/*(!!viaggio_corrente && viaggio_corrente == 'new') ? (
                <Button variant="outline-primary" onClick={(event) => this.callChildSave()}>Salva</Button>
              ) : (
                <></>
              )*/}
          </Navbar.Collapse>
          </Navbar>
          <Modal size="sm" show={this.state.modalDeleteShow} onHide={this.handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cancella Preventivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>Vuoi davvero cancellare il preventivo?</Modal.Body>
            <Modal.Footer>
              <Button variant="link" onClick={this.handleModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={this.deleteTour}>
                Cancellalo!
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal size="sm" show={this.state.modalConfShow} onHide={this.handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Conferma Preventivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>Vuoi davvero confermare il preventivo?</Modal.Body>
            <Modal.Footer>
              <Button variant="link" onClick={this.handleModalClose}>
                Close
              </Button>
              <Button variant="success" onClick={this.confirmTour}>
                Confermalo!
              </Button>
            </Modal.Footer>
          </Modal>
          {/*
          <Route path={`/quotations/list`} render={(props) => (<ListComp {...props} handleSelect={this.handleSelect} />)} />
          */}
          <Route path={`/quotations/list`} render={(props) => (<FormCom {...props} handleSelect={this.handleSelect} />)} />
          {/*
          <Route path={`/quotations/details`} component={QuotationsDetailsComp} />
          */}
          <Route path={`/quotations/details`} render={(props) => <QuotationsDetailsComp {...props} />} />
        </div>
      );
    }
  }
  
  //render(<ControlledTabs />);
  export default QuotationsComp