import React from 'react';
import styled from 'styled-components';
import './App.css';
import { Router, Switch, Route } from 'react-router-dom'

import { Provider } from 'react-redux'

import {store} from './_helpers/store'
import { history } from './_helpers'

import 'bootstrap/dist/css/bootstrap.min.css'

//import ThemeSelectorComp  from './components/ThemeSelectorComp'

import SideBar from './components/SideBar'

import { PrivateRoute } from './routes/PrivateRoute'
import HomeComp from './components/HomeComp'
import LoginPage from './components/LoginPage'
//import LayoutsComp from './components/LayoutsComp'
import RoutesComp from './components/RoutesComp'
import QuotationsComp from './components/QuotationsComp'
import ToursComp from './components/ToursComp'
import PeoplesComp from './components/PeoplesComp'
import CompaniesComp from './components/CompaniesComp'
import DestinationsComp from './components/DestinationsComp'
import TourAttributesComp from './components/TourAttributesComp'

import TestComp from './components/TestComp_tableFooter'

//import GridComponent from './components/GridComponent';
//import FormGridComp from './components/FormGridComp';
//import Page from './routes/Page.js';

//const basename_path = window.location.pathname.toLowerCase();
const basename_path = '/wp-content/themes/cltour';

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0 20px;
    margin-left: ${props => (props.expanded ? 240 : 64)}px;
`;

function withProps(Component, props) {
	return function(matchProps) {
	  return <Component {...props} {...matchProps} />
	}
}

class App extends React.Component {
    state = {
		theme: 'default',
		selected: 'home',
        expanded: false
    };

    onSelect = (selected) => {
        this.setState({ selected: selected });
    };
    onToggle = (expanded) => {
        this.setState({ expanded: expanded });
    };

    render() {
		const { expanded, selected } = this.state
		//const theme = store.getState().theme.theme

        return (
			<div>
				<Provider store={store}>
				{/*
				<ThemeSelectorComp />
				*/}
				<Router basename={basename_path} history={history}>
					<div className="container-fluid">
						<SideBar onToggle={this.onToggle} />
						<Main expanded={expanded}>
							<Switch>
								{/*
								<PrivateRoute exact path='/' component={App}/>
								*/}
								<PrivateRoute exact path='/' component={withProps(HomeComp, {state:{source: {type: 'proc', proc:'timetable'}, layout:'15', layoutForm:'16'}})} />
								<Route path="/login" component={LoginPage} />
								{/*
								<PrivateRoute path='/layouts' component={LayoutsComp}/>
								*/}
								<PrivateRoute path='/routes' component={RoutesComp}/>
								{/*
								<Route path='/page' component={Page}/>
								*/}
								<PrivateRoute path='/quotations' component={QuotationsComp}/>
								<PrivateRoute path='/tours' component={ToursComp}/>
								<PrivateRoute path='/peoples' component={PeoplesComp}/>
								<PrivateRoute path='/companies' component={CompaniesComp}/>
								<PrivateRoute path='/destinations' component={DestinationsComp}/>
								<PrivateRoute path='/tourattributes' component={TourAttributesComp}/>
								{
								<PrivateRoute path='/test' component={TestComp}/>
								}
								
							</Switch>
						</Main>
					</div>
				</Router>
				</Provider>
			</div>
        );
    }
}

export default App;
