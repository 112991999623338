//https://react-jsonschema-form.readthedocs.io/en/latest/
//https://www.npmjs.com/package/react-jsonschema-form-layout-grid
//FORM BUILDER dello stesso oggetto ma NON INSERITO:
//https://github.com/Kinto/formbuilder


import React, { Component } from "react";
//import ReactDOM, { render } from 'react-dom';
import _ from "lodash"
import update from 'immutability-helper';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button'

//import SlidingPane from 'react-sliding-pane';
import SlidePanelComp from './SlidePanelComp'

//import Form from "react-jsonschema-form";
import { withTheme, utils } from '@rjsf/core'
import { Theme as Bootstrap4Theme } from '@rjsf/bootstrap-4'

import {InsertButton} from 'react-bootstrap-table';
import fields from "react-jsonschema-form-extras";

import applyNavs from "react-jsonschema-form-pagination";
//import applyNavs from "react-jsonschema-form-pagination-bs4";
import CustomNavs from "../_helpers/CustomNavs";
import * as Commons from "../_helpers/commons";

//import 'react-bootstrap-table/css/react-bootstrap-table.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
//import 'react-bootstrap-typeahead/css/Typeahead-bs4.min.css';

import LayoutGridField from "../_helpers/LayoutGridField";

import {API_URL_REST} from '../_constants/app.constants';
import {
  schema_layouts_table,
  uiSchema_layouts_table,
  schema_travels,
  uiSchema_travels,
  schema_quotations,
  uiSchema_quotations,
  schema_peopletotravels,
  uiSchema_peopletotravels
} from '../_constants';

import {history} from '../_helpers'

const apiUrlRest = API_URL_REST;

//TEST FUNC
function noPanic(){
  return["A","B","C"]
}

//TEST FUNC
function testFunc(){
  alert('qui');
  return false
}

//NOT USED
const log = (type) => {
  console.log.bind(console, type)
};

const Form = withTheme(Bootstrap4Theme)

//let FormWithPagination = applyNavs(Form)
let FormWithPagination = applyNavs(Form, CustomNavs)
const fields_grid = {
  layout_grid: LayoutGridField
}

/*
const widgets = {
  ImageUpload: ImageUploadWidget
}
*/

let allFields = Object.assign({}, fields, fields_grid);

class FormComp extends React.Component {
  constructor(props, context) {
      super(props, context);
      this.formRef = React.createRef() // create a ref object
      this.state = {
        isDebug: false,
        isLoading: true,
        el_id: false,
        parent_key: false,
        source: '',
        sourceNew: '',
        layout: '',
        //formSchema: {},
        formUiSchema: {},
        formData: {},
        expandedComponent: false,
        layoutForm: '',
        row_current: '',
        panelSource: '',
        panelRow: '',
        isPaneOpen: false,
        isEditTable: false,
        selectedRows:[]
      };
      this.setFormElements = this.setFormElements.bind(this)
      this.openSlidePanel = this.openSlidePanel.bind(this)
      this.fetchLayout = this.fetchLayout.bind(this)
      this.fetchData = this.fetchData.bind(this)
	    this.callFetchData = this.callFetchData.bind(this)
      this.handleUiSchema = this.handleUiSchema.bind(this)
      this.setOptionsAsync = this.setOptionsAsync.bind(this)
      this.expandComponent = this.expandComponent.bind(this)
      this.onDeleteRow = this.onDeleteRow.bind(this)
      this.onSelectRow = this.onSelectRow.bind(this)
      this.onSubmit = this.onSubmit.bind(this)
      this.toUpdateArr = []
  }

	componentDidMount() {
    //this.callFetchData();
    
    if(this.props.location !== undefined && this.props.location.state !== undefined) {
    //if(Commons.objHas(this.props, 'location.state')) {
      this.setState({
        source: this.props.location.state.source,
        layout: this.props.location.state.layout,
        layoutForm: this.props.location.state.layoutForm
      }, () => {
        this.setFormElements(this.props);
      })
    } else if (this.props.sourceNew !== undefined) {
      this.setState({
        sourceNew: this.props.sourceNew,
        source: this.props.source
      }, () => {
        this.setFormElements(this.props);
      })
    } else if (this.props.state !== undefined){
      this.setState({
        source: this.props.state.source,
        layout: this.props.state.layout,
        layoutForm: this.props.state.layoutForm
      }, () => {
        this.setFormElements(this.props);
      })      
    } else {
      this.setFormElements(this.props)
    }
	}

  componentWillReceiveProps(props) {
    //this.setFormElements(props)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.el_id !== prevProps.el_id) {
      this.setFormElements()
    }
    if (this.state.formUiSchema != prevState.formUiSchema) {
      //var newUiSchema = _.cloneDeep(this.state.formUiSchema)
      //inserisce le options degli asynctypeahead all'apertura
      //if (Object.keys(this.state.formUiSchema) == 'table') {
      if ('table' in this.state.formUiSchema) {
        //is table
        var formUiSchema = this.state.formUiSchema.table.table.tableCols
        var specificKey = 'field'
      } else {
        var formUiSchema = this.state.formUiSchema
        var specificKey = 'ui:field'
      }

      //var toUpdateArr = [];
      Object.keys(formUiSchema).map((key, label) => {
        //this.setOptionsAsync(key)
        var myitem = JSON.parse(JSON.stringify(formUiSchema[key]));
        if (myitem[specificKey] == 'asyncTypeahead' || myitem[specificKey] == 'typeahead'){
          
          if(specificKey == 'field') {
            //is table
            var currentOptions = myitem.uiSchema[myitem[specificKey]].options
            var currentSource = myitem.uiSchema[myitem[specificKey]].source
            var currentUrl = myitem.uiSchema[myitem[specificKey]].url
          } else {
            var currentOptions = myitem[myitem[specificKey]].options
            var currentSource = myitem[myitem[specificKey]].source
            var currentUrl = myitem[myitem[specificKey]].url
          }
          //if ((!myitem[myitem['ui:field']].options || myitem[myitem['ui:field']].options.length === 0) && myitem[myitem['ui:field']].source) {
          //if ((!myitem[myitem[specificKey]].options) && myitem[myitem[specificKey]].source) {
          //if ((!currentOptions) && currentSource && this.toUpdateArr.indexOf(key) == -1) {
          if ((!currentOptions) && currentSource && 1==2) {
            this.toUpdateArr.push(key)
            //if (!!myitem[myitem['ui:field']].search) {
              //myitem[myitem['ui:field']].search(myitem[myitem['ui:field']].url, '').then(res => {
              //this.updateUiSchema(key, specificKey, myitem, currentUrl, currentSource, formUiSchema)
              
              fetch(`${currentUrl}?source=${currentSource}`).then(function (res) {
                return res.json();
              }).then(res => {
                //console.log('mah', res)
                //let tempVar = JSON.parse(JSON.stringify(this.state.formUiSchema))
                //tempVar[key][myitem['ui:field']].options = res.data.data;
                if(specificKey == 'field') {
                  //is table
                  //newUiSchema.table.table.tableCols[key][myitem[specificKey]].options = res.data.data
                  
                  let newUiSchema = update(this.state.formUiSchema,
                    {table:{table:{tableCols:{[key]:{uiSchema:{[myitem[specificKey]]: {$merge: {options: res.data.data}}}}}}}});
                  this.setState({formUiSchema: newUiSchema})
                  
                } else {
                  //newUiSchema[key][myitem[specificKey]].options = res.data.data
                  let newUiSchema = update(formUiSchema, {
                    [key]: {[myitem[specificKey]]: {$merge: {options: res.data.data}}}});
                  this.setState({formUiSchema: newUiSchema})
                }
              })
            //} else {
              //TODO: make a fetch with url parameter
            //}
            
          }

          //this.setState(formUiSchema[key].options: myitem["asyncTypeahead"].search())
        }
      }/*, () => {
        if (!!updateUiSchema) {
          this.setState({formUiSchema: newUiSchema})
        }
      }*/);
    }
    //if (this.state.value > prevState.value) {
      //this.render();  
    //}
  }

  componentWillUnmount() {
    //this.props.clearTableData();
    /*
    this.setState({ formSchema: {} });
    this.setState({ formUiSchema: {} });
    this.setState({ el_id: false });
    */
  }

  setFormElements(){
    var {el_id} = this.props
    if(typeof el_id === 'object' && el_id !== null) {
      el_id = el_id.ID
    }

    this.setState({
      el_id: el_id,
      formData: {}
    });

    if(this.props.formData != undefined) {
      var callData = false
    } else {
      var callData = true
    }
    
    //console.log(this.state.el_id);
    /*
    if(props.el_id) {
      this.fetchData(props.el_id);
    } else if(props.el_id == 'new') {
      console.log('Inserimento Nuovo Record - campi vuoti');
    } else {
      //fetch all records in source for table
      this.fetchData(false);
    }
    */
    if(!!this.props.location && !!this.props.location.state && !!this.props.location.state.proc) {
      console.log('qui');
    } else if(!!this.props.schema && !!this.props.uiSchema) {
      let {isDebug = false} = this.props.uiSchema
      this.setState({
        formSchema: this.props.schema,
        formUiSchema: this.handleUiSchema(this.props.uiSchema),
        formData:  this.props.formData,
        isLoading: false,
        isDebug: isDebug,
        layout: {TYPE: 'FORMTABLE'}
       });
    } else if(!!this.props.formSchema && !!this.props.formUiSchema) {
      callData = false
      if(this.props.formUiSchema.table.table.expandedComponent) {
        var expandedComponent = this.props.formUiSchema.table.table.expandedComponent
        this.props.formUiSchema.table.table.expandableRow = this.isExpandableRow
        //this.props.formUiSchema.table.table.expandableRow = () => { return true; }
        this.props.formUiSchema.table.table.expandComponent = this.expandComponent
        this.props.formUiSchema.table.table.expandColumnOptions = {
          expandColumnVisible: true,
          expandColumnComponent: this.expandColumnComponent,
          columnWidth: 50
        }
        this.props.formUiSchema.table.table.options = {}
        this.props.formUiSchema.table.table.options.expandRowBgColor = '#F9F9F9'
        this.props.formUiSchema.table.table.options.expandBy = 'column'
      }
      let {isDebug = false} = this.props.formUiSchema
      this.setState({
        formSchema: this.props.formSchema,
        formUiSchema: this.props.formUiSchema,
        formData:  this.props.formData,
        expandedComponent:expandedComponent,
        isLoading: false,
        isDebug: isDebug,
        layout: {TYPE: 'FORMTABLE'}
       });
    } else if (!!this.props.layout) {
        callData = false
        this.fetchLayout(this.props.layout)
    } else if (!!this.state.layout) {
      callData = false
      const {layout} = this.state
      if(layout instanceof Object) {
        var layoutid = layout.ID
      } else {
        var layoutid = layout
      }
      this.fetchLayout(layoutid, this.state.layoutForm)
    } else if(!!this.props.sourceNew) {
      callData = false
      this.fetchLayout(this.props.sourceNew.layout)
    } else if(this.props.source == 'wp_cl_layouts' || this.state.source == 'wp_cl_layouts'){
      var moduiSchema_layouts_table = uiSchema_layouts_table
      moduiSchema_layouts_table.table.table.leftActions = [{
        //"action": this.openSlidePanel,
        "classNames": "customClassName",
        "editable":false,
        "dataFormat":(cell, row, enumObject, rowIndex, formData, onChange) => (
          <Button variant="link" onClick={() => history.push({pathname: '/layouts/details', state: {source: 'wp_cl_layouts', data:row} })}><i className="fas fa-ellipsis-v" /></Button>
        ),
      }]
      this.setState({
        formSchema: schema_layouts_table,
        formUiSchema: moduiSchema_layouts_table,
        layout: {TYPE: 'FORMTABLE'}
       });
    } else if(this.props.source == 'wp_cl_travels' || this.state.source == 'wp_cl_travels'){
      this.setState({ formSchema: schema_travels, formUiSchema: uiSchema_travels })
    } else if(this.props.source == 'wp_cl_quotations' || this.state.source == 'wp_cl_quotations'){
      this.setState({ formSchema: schema_quotations, formUiSchema: uiSchema_quotations })
    /*
    } else if(this.props.source == 'wp_cl_peoples' || this.state.source == 'wp_cl_peoples') {
      this.setState({ formSchema: schema_peoples, formUiSchema: uiSchema_peoples });
    } else if(props.source == 'wp_cl_companies' || this.state.source == 'wp_cl_companies') {
      this.setState({ formSchema: schema_companies });
      this.setState({ formUiSchema: uiSchema_companies });
    */
    } else if(this.props.layout == 'wp_cl_peopletotravels' || this.state.layout == 'wp_cl_peopletotravels') {
      this.setState({formSchema: schema_peopletotravels, formUiSchema: uiSchema_peopletotravels });
    } else if(this.props.source == 'wp_cl_destinations' || this.state.source == 'wp_cl_destinations') {
      //var { layout = '' } = this.props.location.state
      if(!!this.props.layout) {
        var layout = this.props.layout
      } else if (!!this.props.location) {
        //var layout = this.props.location.state.sourceNew.layout
        var { layout = '', layoutForm = '' } = this.props.location.state
      }
      callData = false
      this.fetchLayout(layout, layoutForm)
      
      //this.setState({ formSchema: test_schema });
      //this.setState({ formUiSchema: uiSchema_peopletotravels });
    } else if(this.props.source == 'wp_cl_companies' || this.state.source == 'wp_cl_companies') {
      //var { layout = '' } = this.props.location.state
      if(!!this.props.layout) {
        var layout = this.props.layout
      } else if (!!this.props.location) {
        var { layout = '', layoutForm = '' } = this.props.location.state
      }
      callData = false
      this.fetchLayout(layout, layoutForm)
      
      //this.setState({ formSchema: test_schema });
      //this.setState({ formUiSchema: uiSchema_peopletotravels });
      
    } else {
      this.setState({ formSchema: {}, formUiSchema: {} });
    }

    /*
    Object.keys(this.state.formUiSchema).map(function (key) {
      var myitem = this.state.formUiSchema[key];
      if (myitem['ui:field'] == 'asyncTypeahead'){
        console.log('qui')
        //this.refs.[key].parentMarkError();
      }
    });
    */

    //console.log(this.state.el_id);
    if(callData) {
      /*
      if(this.props.el_id == 'new') {
        console.log('Inserimento Nuovo Record - campi vuoti');
      } else if(this.props.el_id) {
        this.fetchData(this.props.el_id);
      } else {
        //fetch all records in source for table
        this.fetchData(false);
      }
      */
      this.callFetchData() 
    }

  }

  callFetchData(){
    if(this.props.el_id == 'new') {
      console.log('Inserimento Nuovo Record - campi vuoti');
      if(!!this.props.parent_id && this.state.parent_key) {
        var parent_key = this.state.parent_key.key
        var parent_value = this.props.parent_id
        //var basicData = { parent_key: parent_value}
        var basicData = {}
        basicData[parent_key] = parent_value
        this.setState({formData: basicData, isLoading: false})
      } else {
        this.setState({isLoading: false})
      }
    } else if(this.props.el_id) {
      if(this.props.el_id instanceof Object){
        this.fetchData(this.props.el_id.ID);
      } else {
        this.fetchData(this.props.el_id);
      }
    } else if(!!this.props.TK && Array.isArray(this.props.TK)) {
      //workaround to avoid erroneous fetchdata for table inside expanded rows
      let doFetchData = false
      this.props.TK.forEach((item, key) => {
        if(!!this.props.parent_row[item]) doFetchData = true
        else doFetchData = false
      })
      if (doFetchData) this.fetchData(false)
    } else {
      //fetch all records in source for table
      this.fetchData(false)
    }
  }

  //non usata
  fetchProc(proc) {
    var body = JSON.stringify({ "proc": proc })
    //}

    fetch(apiUrlRest+'getproc', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: body
    })
    // We get the API response and receive data in JSON format...
    .then(response => response.json())
    // ...then we update the users state
    .then(data => {

    })

  }

  fetchLayout(el_id, layoutForm) {
    //console.log(this.state.el_id);
    var expandedComponent = false

    /*
    this.setState({
      isLoading: true,
    })
    */
    //if(this.props.layout !== undefined){
      //var body = JSON.stringify({ "layout": this.props.layout, 'where': this.props.layout+'.ID ='+el_id })
    //} else {
      var body = JSON.stringify({ "source": 'wp_cl_layouts', 'where': 'ID ='+el_id })
    //}

    fetch(apiUrlRest+'get', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: body
    })
    // We get the API response and receive data in JSON format...
    .then(response => response.json())
    // ...then we update the users state
    .then(data => {
      var res = data.data.data[0]
      var schema = JSON.parse(data.data.data[0].SCHEMA)
      var uischema = res.UISCHEMA !== null ? JSON.parse(data.data.data[0].UISCHEMA) : {}
      //console.log(data)
      /*
      var res = data.data.data[0].map(v => {
        parseInt(v, 10)
        });
      */

      var isEditTable = false
      Object.keys(uischema).map((key, label) => {
        //this.setOptionsAsync(key)
        //var myitem = JSON.parse(JSON.stringify(this.state.formUiSchema[key]));
        if (uischema[key]['ui:field'] == 'asyncTypeahead'){
          if(!uischema[key]['asyncTypeahead'].optionsPath)
            uischema[key]['asyncTypeahead'].optionsPath = 'data.data'
          //uischema[key]['asyncTypeahead'].search = eval(uischema[key]['asyncTypeahead'].search);
          //uischema[key]['asyncTypeahead'].search = uischema[key]['asyncTypeahead'].search.parseFunction();
          uischema[key]['asyncTypeahead'].labelKey = eval(uischema[key]['asyncTypeahead'].labelKey)
          //uischema[key]['asyncTypeahead'].search = function(url, query) { fetch(`${url}?query=${query}&source=${uischema[key]['asyncTypeahead'].source}`).then(function (res) {return res.json();})}
          uischema[key]['asyncTypeahead'].search = (url, query) => fetch(`${url}?query=${query}&source=${uischema[key]['asyncTypeahead'].source}`).then(function (res) {return res.json();})
        } else if (uischema[key]['ui:field'] == 'table') {

          uischema[key].table.version ='4'
          if(typeof uischema[key].table.options === "undefined") {
            uischema[key].table.options = {}
          }
          
          //uischema.table.table.options.insertBtn = eval(uischema.table.table.options.insertBtn)
          //uischema.table.table.options = this.options
          if(uischema[key].table.insertAction) {
            uischema[key].table.options.insertBtn = this.createCustomInsertButton
            uischema[key].table.options.insertAction = uischema[key].table.insertAction
          }
          /*
            uischema[key].table.options = {
              insertBtn: this.createCustomInsertButton,
              insertAction: uischema[key].table.insertAction
            }
          */
          
          if(uischema[key].table.selectRow) {
            uischema[key].table.selectRow.onSelect= this.onSelectRow
          }

          if(uischema[key].table.deleteRow) {
            uischema[key].table.options.onDeleteRow = this.onDeleteRow
          }
  
          //uischema.table.table.leftActions[0].action = eval(uischema.table.table.leftActions[0].action)
          if(uischema[key].table.addleftActions != false) {
            var leftAction = this.openSlidePanel
            if (uischema[key].table.leftActionsFunc != undefined && uischema[key].table.leftActionsFunc == "handleSelect"){
              //var leftAction = eval(uischema.table.table.leftActionsFunc)
              var leftAction = this.props.handleSelect
            }
            uischema[key].table.leftActions = [{
              //"action": this.openSlidePanel,
              //"className": "customClassName",
              //"columnClassName": "customClassName",
              "width":"40",
              "editable":false,
              "expandable":false,
              "export":false,
              "dataFormat":(cell, row, enumObject, rowIndex, formData, onChange) => (
                <Button variant="link" onClick={() => leftAction(row)}><i className="fas fa-ellipsis-v" /></Button>
              ),
            }]
          }
  
          if(uischema[key].table.expandedComponent) {
            expandedComponent = uischema[key].table.expandedComponent
            uischema[key].table.expandableRow = this.isExpandableRow
            //uischema.table.table.expandableRow = () => { return true; }
            uischema[key].table.expandComponent = this.expandComponent
            uischema[key].table.expandColumnOptions = {
              expandColumnVisible: true,
              expandColumnComponent: this.expandColumnComponent,
              columnWidth: 50
            }
            //uischema.table.table.options.expandRowBgColor = 'rgba(153, 153, 153, 0.13)'
            uischema[key].table.options.expandRowBgColor = '#F9F9F9'
            uischema[key].table.options.expandBy = 'column'
          }
  
          if(uischema[key].table.footerData != undefined) {
            uischema[key].table.footerData[0].map(function(item, itemkey){
              if(item.formatter == 'columnTotal'){
                
                uischema[key].table.footerData[0][itemkey].formatter = (tableData) => {
                  let label = 0;
                  for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
                    const value = tableData[i][item.dataFieldRef];
                    if (value !== null && value !== undefined) {
                      label += parseFloat(value);
                    }
                  }
                  return (
                    <strong>{ label }</strong>
                  );
                }
              }
            }.bind(this))
          }

          //workaround to avoid class added by react-jsonschema-form-extras that broke correct rendering
          uischema[key].table.tableCols.map(function(item, colkey){
            //force cell editable like row editable attrib
            if(uischema[key].table.editable){
              //uischema.table.table.tableCols[colkey].editable = uischema.table.table.editable
              isEditTable = uischema[key].table.editable
              //uischema[key].table.tableCols[colkey].editable = true
            } else {
              uischema[key].table.tableCols[colkey].editable = false
            }
            
            if(!item.className){
              uischema[key].table.tableCols[colkey].className = "customClassName"
            }
            if(!item.columnClassName){
              uischema[key].table.tableCols[colkey].columnClassName = "customClassName"
            }
            if (item['field'] == 'asyncTypeahead'){
              if(!uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].optionsPath)
                uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].optionsPath = 'data.data'
              if(uischema[key].table.tableCols[colkey].editable == false)
                uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].disabled = true
              if(uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].presetOptions == true)
                uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].minLength = 0
                //uischema[key]['asyncTypeahead'].search = eval(uischema[key]['asyncTypeahead'].search);
              //uischema[key]['asyncTypeahead'].search = uischema[key]['asyncTypeahead'].search.parseFunction();
              uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].labelKey = eval(uischema.table.table.tableCols[colkey].uiSchema['asyncTypeahead'].labelKey)
              //uischema.table.table.tableCols[key].uiSchema['asyncTypeahead'].search = function(url, query) { fetch(`${url}?query=${query}&source=${uischema.table.table.tableCols[colkey].uiSchema['asyncTypeahead'].source}`).then(function (res) {return res.json();})}
              if(!!uischema.table.table.tableCols[colkey].uiSchema['asyncTypeahead'].proc) {
                uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].search = (url, query) => fetch(`${url}?query=${query}&proc=${uischema.table.table.tableCols[colkey].uiSchema['asyncTypeahead'].proc}&parentid=${this.props.parent_id}`).then(function (res) {return res.json();})
              } else {
                uischema[key].table.tableCols[colkey].uiSchema['asyncTypeahead'].search = (url, query) => fetch(`${url}?query=${query}&source=${uischema.table.table.tableCols[colkey].uiSchema['asyncTypeahead'].source}`).then(function (res) {return res.json();})
              }
            }
          }.bind(this))
        }
      })

      var layoutForm = this.state.layoutForm
      //if(!!this.props.location && !!this.props.location.state.layoutForm){
      if(Commons.objHas(this.props, 'location.state.layoutForm')) {
        var layoutForm = this.props.location.state.layoutForm
      }else if (!!this.props.layoutForm){
        var layoutForm = this.props.layoutForm
      }

      let {isDebug = false} = uischema

      this.setState({
        layout: res,
        layoutForm: layoutForm,
        //formSchema: JSON.parse(JSON.stringify(data.data.data[0].SCHEMA)),
        formSchema: schema,
        //formUiSchema: JSON.parse(data.data.data[0].UISCHEMA),
        formUiSchema: uischema,
        //layoutForm: layoutForm,
        isDebug: isDebug,
        isLoading: false,
        expandedComponent: expandedComponent,
        parent_key: (res.PARENTEL != '') ? JSON.parse(res.PARENTEL) : '',
        isEditTable: isEditTable
      },() => this.callFetchData())
    })
    // Catch any errors we hit and update the app
    .catch(error => this.setState({ error, isLoading: false }));
  }

  fetchData(el_id) {
    //console.log(this.state.el_id);
    //if(this.state.el_id)

    /*
    this.setState({
      isLoading: true,
    })
    */
    var bodyobj = {}
    var { source = '', layout = '' } = this.props
    if(!!this.props.source) {
      var source =this.props.source
      bodyobj.source = source
    } else if(!!this.props.layout) {
      var layout = this.props.layout
      bodyobj.layout = layout
    }

    //if(!!this.props.location && !!this.props.location.state && !!this.props.location.state.source) {
    if(Commons.objHas(this.props, 'location.state.source')) {
      var { source = '' } = this.props.location.state
      bodyobj.source = source
    } else if(Commons.objHas(this.props, 'state.source')) {
      var { source = '' } = this.props.state
      bodyobj.source = source
    }
    
    //if(this.props.layout !== undefined){
    //if(layout.length){
      //var bodyobj = { "layout": this.props.layout, 'where': this.props.layout+'.ID ='+el_id }
      //var bodyobj = { "layout": this.props.layout }
      //if (el_id.length) {
      //if (!!el_id) {
        //bodyobj.where = this.props.layout+'.ID ='+el_id
      //}

    //} else {
      //var bodyobj = { "source": this.props.source, 'where': 'ID ='+el_id }
      //if (source.length) {
        //var bodyobj = {"source": source}
      //} 
      /*else if (source.length) {
        var bodyobj = {"source": sourceFromlocation.source}
      }
      */
      
      if(!!this.props.where) {
        bodyobj.where = this.props.where
      } else if (!!el_id) {
        if(!!this.props.TK){
          if(Array.isArray(this.props.TK)) {
            bodyobj.where = this.props.TK.map((item, key) =>
              item+' = '+this.props.parent_row[item]+' '
            )
          } else {
            bodyobj.where = this.props.TK+' = '+el_id
          }
          bodyobj.props = this.props
        } else {
          bodyobj.where = 'ID ='+el_id
          bodyobj.props = this.props
        }
      } else {
        bodyobj.props = this.props
      }
    //}

    if(!!this.props.GROUPBY){
        bodyobj.groupby = this.props.GROUPBY
    }

    var body = JSON.stringify(bodyobj);

    fetch(apiUrlRest+'get', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: body
    })
    // We get the API response and receive data in JSON format...
    .then(response => response.json())
    // ...then we update the users state
    .then(data => {
      //var res = data.data.data[0]
      if(this.state.layout.TYPE == 'FORMMIXED') {
        var res = {}
        Object.keys(this.state.formUiSchema).map((key, label) => {
          //console.log(key, label)

          //wordkaround perchè perde l'ID
          if (key == 'ID')
            this.state.formSchema.properties[key].default = data.data.data[key]

          if (this.state.formUiSchema[key]['ui:field'] == 'table'){
            res['table'] = data.data.data.table || []
            
            //manage data type array
            if(!!res.table && !!res.table[0]) {
              Object.keys(res.table[0]).map(
                (key, idx)=>{
                  var properties = this.state.formSchema.properties.table.items.properties[key]
                  if(!!properties && properties.type == 'array') {
                    Object.keys(res.table).map(
                      (datakey, dataidx)=>{
                        if(res.table[datakey][key]){
                          res.table[datakey][key] = JSON.parse(res.table[datakey][key]);
                        }
                    }) 
                  }
    
                }
              )
            }
            
          } else {
            //use default if empty data for key
            // if(!!this.state.formSchema.properties[key].default && !data.data.data[key]){
            //   res[key] = this.state.formSchema.properties[key].default
            // } else {
            //   res[key] = data.data.data[key]
            // }
            const hasDefault = this.state.formSchema?.properties?.[key]?.default;
            res[key] = hasDefault && !data.data?.data?.[key] ? hasDefault : data.data?.data?.[key];

            //manage data type array
            var properties = this.state.formSchema.properties[key]
            if(!!properties && !!res[key] && properties.type == 'array')
              res[key] = JSON.parse(res[key]);
          }
        })
      } else if (this.state.layout.TYPE == 'FORMTABLE') {
        var res = {'table': data.data.data}
        //manage data type array
        if(!!res.table[0]) {
          Object.keys(res.table[0]).map(
            (key, idx)=>{
              var properties = this.state.formSchema.properties.table.items.properties[key]
              if(!!properties && properties.type == 'array') {
                Object.keys(res.table).map(
                  (datakey, dataidx)=>{
                    if(res.table[datakey][key]){
                      res.table[datakey][key] = JSON.parse(res.table[datakey][key]);
                    }
                }) 
              }

            }
          )
        }
      } else {
        if(data.data.data[0] != undefined)
          var res = data.data.data[0]
        else
        var res = data.data.data
      
        //manage data type array
        if(!!res) {
          Object.keys(res).map(
            (key, idx)=>{
              var properties = this.state.formSchema.properties[key]
              if(!!properties && !!res[key] && properties.type == 'array')
                res[key] = JSON.parse(res[key]);
            }
          )
        }
      }

      /*
      var renObjData = res.map(v => {
        JSON.parse(data)
      });
      
      var renObjData = data.data.data[0].map(v => {
        parseInt(v, 10)
        });
      var renObjData = data.data.data[0].map(function(data, idx) {
          return JSON.parse(data);
        });
      */

      if(!!this.state.parent_key) {
        res[this.state.parent_key.key] = this.props.parent_id.ID
      }

      this.setState({
        formData: res,
        isLoading: false,
        parent_key: (this.props.parent_id) ? this.props.parent_id : false
      })
      }
    )
    // Catch any errors we hit and update the app
    .catch(error => this.setState({ error, isLoading: false }));
  }

  //TODO: move here all code in fetchLayout's fetch.success
  handleUiSchema(uischema){
    var isEditTable = false
    Object.keys(uischema).map((key, label) => {
      if (uischema[key]['ui:field'] == 'table') {
        if(uischema[key].table.footerData != undefined) {
          uischema[key].table.footerData[0].map(function(item, itemkey){
            if(item.formatter == 'columnTotal'){
              uischema[key].table.footerData[0][itemkey].formatter = (tableData) => {
                let label = 0;
                for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
                  label += parseFloat(tableData[i].price)
                }
                return (
                  <strong>{ label }</strong>
                );
              }
            }
          }.bind(this))
        }
      }
    })
    return uischema
  }

  //inserita in DidUpdate - da buttare
  setOptionsAsync(key){
    var myitem = this.state.formUiSchema[key];
    if (myitem['ui:field'] == 'asyncTypeahead'){
      //console.log('qui')
      myitem["asyncTypeahead"].search().then(res => console.log('mah', res))
      //this.setState(formUiSchema[key].options: myitem["asyncTypeahead"].search())
    }
  }

  openSlidePanel(row, source = '', layout =''){
    //save form data to prevent lose on slide open
    //this.onSubmit(this.state.formData)
  
    if(!!row.panelSource) {
      this.setState({
        isPaneOpen: true,
        panelRow: row,
        panelSource: row.panelSource,
        //layoutForm: layout
        layoutForm: (!!this.props.layoutForm) ? this.props.layoutForm : this.state.layoutForm
      }) 
    }else if(!!row.source) {
      this.setState({
        isPaneOpen: true,
        panelRow: row,
        panelSource: row.source,
        //layoutForm: layout
        layoutForm: (!!this.props.layoutForm) ? this.props.layoutForm : this.state.layoutForm
      }) 
    }else if (!!source && !!layout) {
      this.setState({
        isPaneOpen: true,
        panelRow: row,
        panelSource: source,
        layoutForm: layout,
      })
    } else if (!!source) {
      this.setState({
        isPaneOpen: true,
        panelRow: row,
        panelSource: source,
        layoutForm: (!!this.props.location) ? this.props.location.state.layoutForm : ""
      })
    } else if (!!this.props.panelSource) {
      this.setState({
        isPaneOpen: true,
        panelRow: row,
        panelSource: this.props.panelSource,
        layoutForm: (!!this.props.layoutForm) ? this.props.layoutForm : this.state.layoutForm
      })      
    } else {
      this.setState({
        isPaneOpen: true,
        panelRow: row,
        panelSource: this.state.source,
        layoutForm: (!!this.props.layoutForm) ? this.props.layoutForm : this.state.layoutForm
      })
    }
  }

  slidePane(action){
    this.setState({isPaneOpen: action})
  }

  //TABLE ATTRIBUTES
  createCustomInsertButton = (onClick) => {
    if(this.state.formUiSchema.table.table.insertAction == 'inline'){
      var action = () => this.appendToFormData()
    } else {
      var id = {'ID': 'new'}
      if(this.state.formUiSchema.table.table.insertSourceData)
        id = this.state.formUiSchema.table.table.insertSourceData
      var sourceEl = this.state.source
      if(this.state.formUiSchema.table.table.insertSource)
        sourceEl = this.state.formUiSchema.table.table.insertSource
      var layoutEl = this.props.layoutForm
      if(this.state.formUiSchema.table.table.insertLayout)
        layoutEl = this.state.formUiSchema.table.table.insertLayout
      var action = () => this.openSlidePanel(id, sourceEl, layoutEl)
    }

    return (
      <InsertButton
        /*
        btnText='CustomInsertText'
        btnContextual='btn-warning'
        className='my-custom-class'
        btnGlyphicon='glyphicon-edit'
        */
        //onClick={ () => this.openSlidePanel(onClick) }
        //onClick={ () => this.openSlidePanel({'ID': 'new'}, this.state.source, this.props.layoutForm) }
        //onClick={ () => this.openSlidePanel(false) }
        onClick={action}
        />
    );
    // If you want have more power to custom the child of InsertButton,
    // you can do it like following
    // return (
    //   <InsertButton
    //     btnContextual='btn-warning'
    //     className='my-custom-class'
    //     onClick={ () => this.handleInsertButtonClick(onClick) }>
    //     { ... }
    //   </InsertButton>
    // );
  }

  appendToFormData = () => {
    //let { schema, uiSchema, formData, registry: { fields } } = this.state;
    let {formSchema, formData } = this.state
    var fieldSchema = formSchema.properties.table.items
    let newVal = utils.getDefaultFormState(fieldSchema, {})
    //if (!!this.state.el_id) {
      if(!!this.props.TK){
        if(Array.isArray(this.props.TK)) {
          this.props.TK.map((item, index) => (
            newVal[item] = this.props.parent_row[item]
          ))
        } else {
          //newVal[this.props.TK] = this.state.el_id
          newVal[this.props.TK] = this.props.parent_row[this.props.TK]
        }
      }
    //}
    //return this.appendToArray(formData, newVal)
    //this.currentForm.handleOnChange(this.appendToArray(formData, newVal))
    var added = this.appendToArray(formData, newVal)
    var addedTable = {table: added}
    this.setState({formData: addedTable})
  }

  appendToArray = (formData, newVal) => {
    //let { uiSchema: { collapse: { addToBottom = true } = {} } } = this.props;
    if(!formData.table) formData.table = []
    if (formData.table.some(v => utils.deepEquals(v, newVal))) {
      return formData.table;
    } else {
      // newVal can be either array or a single element, concat flattens value
      //if (addToBottom) {
      if (1==1) {
        return formData.table.concat(newVal);
      } else {
        return [newVal].concat(formData.table);
      }
    }
  };

  createMostCustomInsertButton = (onClick) => {
    //onClick.preventDefault();
    return (
      <button style={ { color: 'red' } } onClick={ this.onClickAddRow }>Add rows</button>
    );
  }

  createOpenPanelButton = (onClick) => {
    return (
      <Button variant="link" onClick={() => this.openSlidePanel(onClick.row)}><i className="fas fa-ellipsis-v" /></Button>
    );
  }
  
  //DA BUTTARE?
  options = {
    insertBtn: this.createMostCustomInsertButton
  };

  //DA BUTTARE?
  onClickAddRow = () => {
    alert('qui');
  }

  isExpandableRow(row) {
    /*
    if (row.id < 2) return true;
    else return false;
    */
    return true;
  }

  expandComponent(row) {
    const {source, layout, TK} = this.state.expandedComponent;
    /*
    let domNode = document.createElement('div');
    ReactDOM.createPortal(
      <Ciao />,
      domNode
    );
    
    return (
      <div>
        <FormComp el_id={row.ID} source={source} sourceNew={{layout: layout}} parent_id={this.state.el_id} parent_row={row} TK={TK}/>
      </div>
    );
    */
    
    /*
    this.state.expandedComponent.map((el, index) => {
      const propsObj = el.props.reduce((result, prop) => {
        result[prop] = true;
        return result;
      }, {});
    })
    //{...propsObj}
    
    Object.values(map);
    {...this.state.expandedComponent.map(property => "(" + property + ") ")}
    */

    return (
      <div>
        <FormComp el_id={row.ID} sourceNew={{layout: layout}} parent_id={this.state.el_id} parent_row={row} {...this.state.expandedComponent}/>
      </div>
    );
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      //content = (isExpanded ? '(-)' : '(+)' );
      content = (isExpanded) ? (<i className="fas fa-minus" aria-hidden="true"></i>) : (<i className="fas fa-plus" aria-hidden="true"></i>);
    } else {
      content = ' ';
    }

    return (
      <div> { content } </div>
    );
  }

  handleTransformErrors = (errors) => {

    //Step through and find the type validation errors.
    //Remove them if the instance value can be coerced to a number
    for (let index = 0; index < errors.length; index++) {
        const { name, argument, instance } = errors[index];

        if (name === 'type' && 
            Array.isArray(argument) &&
            argument.includes('number') &&
            !isNaN(instance)) 
        {
            errors.splice(index, 1);
            index--;
        }
    }
    return errors;
  }

  onSelectRow(row, isSelected, e) {
    this.setState({
      selectedRows: [...this.state.selectedRows, row]
    })
  }

  onDeleteRow(rows){
    //alert('qui deleteTour')
    let ids = []
    let deletedRows = []
    rows.map((item,i)=> {
      if (typeof this.formRef.current.formData.table[item].ID !== 'undefined') {
        ids.push(this.formRef.current.formData.table[item].ID)
      }
      deletedRows.push(this.formRef.current.formData.table[item])
      this.formRef.current.formData.table.splice(item, 1)
    })

    if(Commons.getFromObj(this.formRef.current.props, 'schema.deleteproc')) {
    //if(Commons.objHas(this.formRef.current.props, 'schema.deleteproc')) {
      var bodyobj = {
        deleteproc: this.formRef.current.props.schema.deleteproc,
        formData: this.formRef.current.formData,
        deletedRows: deletedRows
      }
    } else {
      var source = ''
      if(Commons.getFromObj(this.formRef.current.props, 'schema.properties.table.items.properties.source.default')) {
        source = this.formRef.current.props.schema.properties.table.items.properties.source.default
      } else if(Commons.getFromObj(this.formRef.current.props, 'schema.properties.source.default')) {
        source = this.formRef.current.props.schema.properties.source.default
      } else if(Commons.getFromObj(this.formRef.current.props, 'schema.properties.source')) {
        source = this.formRef.current.props.schema.properties.source
      } else {
        console.error('source not found');
        return false
      }
      var bodyobj = {
        source: source,
        ids: ids
      }    
    }

    var body = JSON.stringify(bodyobj);
    fetch(apiUrlRest+'delete', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: body
    })
    // We get the API response and receive data in JSON format...
    .then(response => response.json())
    // ...then we update the users state
    .then(data => {
      var res = data.data
      /*
      this.setState({
        modalDeleteShow: false,
        isLoading: false,
        isPaneOpen: false
      })
      */
      //this.props.refresh()
      //this.props.slidePane(false)
      this.setFormElements()
    })
    .catch(error => this.setState({ error, isLoading: false }));
  }

  onChange = ({formData},e) => {
    console.log('changed', formData)
    //this.onSubmit(formData, e)
  }

  handleBlur = ({formData},e) => {
    //console.log('handleBlur')
  }

  onError = ({formData},e) => {
    console.log('onError')
  }

  onSubmit = ({formData} = null,e) => {
    const {formUiSchema} = this.state

    if (e != undefined) {
      e.preventDefault();
    }
    
    //log("submitted");
    if(Commons.hasOwnPropertiesRecursively(formUiSchema, 'onSubmit.onlySelected')){
      //console.log(this.state.selectedRows)
      //formData = this.state.selectedRows
      formData = {}
      formData.data = this.state.selectedRows
    } else if(!formData) {
      formData = this.formRef.current.formData
    }

    if(Commons.objHas(formUiSchema, 'onSubmit.proc')){
      formData.proc = formUiSchema.onSubmit.proc
      formData.source = {proc: formUiSchema.onSubmit.proc}
    } else if(!formData.source || !formData.source) {
      formData.source = this.props.source
    }

    if(this.state.parent_key)
      formData.parent_key = this.state.parent_key

    fetch(apiUrlRest+'write', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(formData)
    })
    // We get the API response and receive data in JSON format...
    .then(response => response.json())
    // ...then we update the users state
    .then(data => {
      var updateState = {
        isLoading: false,
        //formData: data.data.data[0],
		    //formData: data.data.data,
        isPaneOpen: false,
        selectedRows: []
      }

      if(this.state.layout.TYPE == "FORM")
        updateState.formData = data.data.data

      if(data.refresh !== undefined && !data.refresh)
        delete updateState.formData

      this.setState(updateState,
        //(this.props.refresh != undefined) ? this.props.refresh() : () => null
        (this.props.refresh != undefined) ? this.props.refresh() : () => this.setFormElements()
      )
    })
    // Catch any errors we hit and update the app
    .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    //let currentForm
    const { isLoading, formSchema, formUiSchema, formData, error } = this.state

    if(error != undefined) {
      return (<div style={{color: "red"}}>{error.name}<br/>{error.message}<br/>{error.stack}</div>)
    }

    if (!isLoading !== undefined && isLoading == false) {
      if(formSchema == undefined || formUiSchema == undefined) {
        return (<div>manca lo schema ovvero uischema</div>)
      }
      /*
      if(!Object.keys(formSchema).length || !Object.keys(formUiSchema).length) {
        return (<div>lo schema e/o uischema vuoto</div>)
      }
      */
      if(!Object.keys(formSchema).length) {
        return (<div>lo schema è vuoto</div>)
      }

      if (!!this.state.panelSource) {
        var panelSource = this.state.panelSource
      } else {
        var panelSource = this.state.source
      }
    
      if(this.state.isDebug) {
        console.debug('formSchema',formSchema)
        console.debug('formUiSchema',formUiSchema)
        console.debug('formData',formData);
      }

      return (
        <div>
          <FormWithPagination
          schema={formSchema}
          uiSchema={formUiSchema}
          noValidate={true}
          fields={allFields}
          formData={formData}
          //widgets={widgets}
          //onChange={log("changed")}
          //onChange={this.onChange}
          onSubmit={this.onSubmit}
          onError={this.onError}
          //safeRenderCompletion={true}
          //onBlur={this.handleBlur}
          //transformErrors={this.handleTransformErrors}
          //ref={(form) => {this.currentForm = form;}}
          ref={this.formRef}
          formContext={{ currentForm: this }}>
            
            {(!formUiSchema.table) ?
              <div>
              <button type="submit" className="btn-floating" ><i className="far fa-save"></i></button>
              {/*
              <button type="button" className="btn-floating" ><i class="fas fa-trash"></i></button>
              */}
              </div>
              : (this.state.isEditTable || formUiSchema.saveBtn ?
                  (formUiSchema.saveBtn == 'floating' ?
                    <button type="submit" className="btn-floating" onClick={() => this.onSubmit(this.formRef.current.formData)}><i className="far fa-save"></i></button>
                  :
                  <div>
                    <button type="button" className="btn btn-info" onClick={() => this.onSubmit(this.formRef.current.formData)}><i className="far fa-save"></i> Salva</button>
                    {/*
                    <button type="button" className="btn btn-info" onClick={({ formData }, e) => this.onSubmit({ formData }, e).bind(this)}><i className="far fa-save"></i> Salva 2</button>
                    <button type="submit" class="btn btn-info"><i className="far fa-save"></i> Salva</button>
                    */}
                  </div>)
              : <div></div>)
            }

          </FormWithPagination>
          {(!!this.props.sourceNew) ?
            /*
            <SlidePanelComp isPaneOpen={this.state.isPaneOpen} row={this.state.row_current} parent_id={this.state.el_id} source={this.state.source} sourceNew={{"layout":this.state.layoutForm}} slidePane={() => this.slidePane()} layout={this.state.layoutForm} comp={this.props.panelBlock} refresh={() => this.fetchData()}/>
            */
            <SlidePanelComp isPaneOpen={this.state.isPaneOpen} row={this.state.panelRow} parent_id={this.state.el_id} source={panelSource} sourceNew={{"layout":this.state.layoutForm}} slidePane={() => this.slidePane()} layout={this.state.layoutForm} comp={this.props.panelBlock} refresh={() => this.setFormElements()}/>
            : 
            <SlidePanelComp isPaneOpen={this.state.isPaneOpen} row={this.state.panelRow} parent_id={this.state.el_id} source={panelSource} slidePane={() => this.slidePane()} layout={this.state.layoutForm} comp={this.props.panelBlock} refresh={() => this.setFormElements()}/>
          }
          </div>
      )
    } else {
			return (
				<div className="mx-auto text-center">
					<Spinner className="mx-auto" animation="grow" variant="info" />
				</div>
			)
		}
  }
}

export default FormComp