import React, { Component } from "react";

import applyRules from 'react-jsonschema-form-conditionals';
import Engine from 'json-rules-engine-simplified';
//import Form from "react-jsonschema-form";
import { withTheme } from '@rjsf/core'
import { Theme as Bootstrap4Theme } from '@rjsf/bootstrap-4'
import fields from "react-jsonschema-form-extras";
//import { withTheme } from 'react-jsonschema-form';
//import MUITheme from "react-jsonschema-form/themes/material-ui";

import LayoutGridField from "../_helpers/LayoutGridField";

import { API_URL_REST } from '../_constants/app.constants';

const apiUrlRest = API_URL_REST;

const schema = {
    title: "Pagamenti",
    type: "object",
    //required: ["name"],
    "definitions": {
        "payments": {
          "type": "object",
          "properties": {
            "type": {
              "type": "string",
              enum: ['Conferma','Acconto','Saldo'],
              title: "Tipo Pagamento",
              "default": "Conferma"
            },
            "percentage": {
                "type": "string"
            },
            "value": {
              "type": "string"
            },
            "expiration": {
              "type": "string", "format": "date"
            },
          }
        },
        "costs": {
            "type": "object",
            "properties": {
              "DESCR": {
                  "type": "string"
              },
              "value": {
                "type": "string",
                "default": "0"
              }
            }
          }
    },
    properties: {
        source:{"type": "string","title":"source", "default":"wp_cl_travels"},
        COSTOPROGETTO: {
            type: "string", 
            "title": "COSTO PROGETTO",
        },
        SALDO: {type: "string"},
        SALDOTOT: {type: "number"},
        "COSTS": {
            "type": "array",
            "title": "Altri Costi",
            "items": {
                "$ref": "#/definitions/costs"
            }
        },
        "SALDOEXPIR": {
            "type": "string", "format": "date", "title": "Scadenza",
        },
        "ACCONTI": {
            "type": "array",
            //"title": "A list of strings",
            "minItems": 1,
            "items": {
                "$ref": "#/definitions/payments"
            }
        },
        rte: {
            type: "string",
            title: "RTE",
            default: "<p><br></p>",
          },
    }
  };

const uiSchema = {
    source: {"ui:widget": "hidden"},
    ACCONTI: {
        type:{
            "ui:widget": "select" // could also be "radio"
        },
        "ui:options": {
            "orderable": false
        },
    },
    COSTS: {
        "ui:options": {
            "orderable": false
        },
    },
    'ui:field': 'layout_grid',
    'ui:layout_grid':
    { 'ui:row': [
        { 'ui:col': { md: 12, children: [
            { 'ui:group': 'Pagamenti',
                'ui:row': [
                    { 'ui:col': { md: 3, children: ['COSTOPROGETTO'] } },
                    { 'ui:col': { md: 3, children: ['SALDO']}},
                    { 'ui:col': { md: 3, children: ['SALDOTOT']}},
                    { 'ui:col': { md: 3, children: ['SALDOEXPIR']}},
            ]},
        ] } },
        { 'ui:col': { md: 6, children: [
            { 'ui:group': 'Gestione Acconti', 'ui:row': [
                { 'ui:col': { md: 12, children: ['ACCONTI']}},
            ],
            },
        ] } },
        { 'ui:col': { md: 6, children: [
            { 'ui:group': 'Costi Aggiuntivi',
            'ui:row': [
                { 'ui:col': { md: 12, children: ['COSTS']}},
            ]},
        ] } },
    ] },
    rte: {
        updateOnBlur: true,
        "ui:field": "collapsible",
        collapse: {
            field: "rte",
            collapsed: true,
        },
        "ui:autofocus": true,
        classNames: "col-md-12",
        }
};

const rules = [
    {
        conditions: {
            COSTOPROGETTO: { not: "empty" },
            /*
            ACCONTI: {
                percentage: { not: "empty" },
            }
            */
        },
        event: {
            type: "updateVALUE"
        }
    },{
        conditions: {
            SALDO: { not: "empty" },
            /*
            COSTS: {
                value: { not: "empty" },
            }
            */
        },
        event: {
            type: "updateVALUEsaldoTot"
        }
    }/*,{
        conditions: {
            ACCONTI: {
                value: { not: "empty" },
            }
        },
        event: {
            //type: "updateVALUEsaldo"
            type:"updateTEST"
        }
    },
    {
        conditions: {
            COSTOPROGETTO: { not: "empty" },
            ACCONTI: {
                value: { not: "empty" },
            }
        },
        event: {
            type: "updatePERCENTAGE"
        }
    }
    */
];
 
 
let extraActions = {
    updateVALUE: function(params, schema, uiSchema, formData) {
        formData.SALDO = formData.COSTOPROGETTO
        formData.ACCONTI.map((item, key) => {
            if(key>0){
            //if (item.value  != undefined && item.percentage == undefined) {
                //formData.ACCONTI[key].percentage = ((parseFloat(formData.ACCONTI[key].value) / parseFloat(formData.COSTOPROGETTO) ) *100).toString() 
            //} else if (item.percentage  != undefined && !item.value == undefined) {
                formData.ACCONTI[key].value = (parseFloat(formData.COSTOPROGETTO) * (parseFloat(formData.ACCONTI[key].percentage) / 100) ).toString()
            }
            
            formData.SALDO = (parseFloat(formData.SALDO) - (parseFloat(formData.ACCONTI[key].value)) ).toString()
        });
    },
    updateAccontoPercentage: function(params, schema, uiSchema, formData) {
        formData.ACCONTI.map((item, key) =>
            formData.ACCONTI[key].percentage = ((parseFloat(formData.ACCONTI[key].value) / parseFloat(formData.COSTOPROGETTO) * 100) ).toString()
        );
    },
    updateVALUEsaldo: function(params, schema, uiSchema, formData) {
        formData.SALDO = formData.COSTOPROGETTO
        formData.ACCONTI.map((item, key) =>
            //formData.SALDO = formData.SALDO + 1
            formData.SALDO = (parseFloat(formData.SALDO) - (parseFloat(formData.ACCONTI[key].value)) )
        );
    },
    updateVALUEsaldoTot: function(params, schema, uiSchema, formData) {
        formData.SALDOTOT = formData.SALDO
        if(formData.COSTS != undefined) {
            formData.COSTS.map((item, key) =>
                formData.SALDOTOT = (parseFloat(formData.SALDOTOT) + (parseFloat(formData.COSTS[key].value)) )
            );
        }
    },
    updateACCONTO: function(params, schema, uiSchema, formData) {
        //formData.sum = Number(formData.a) + Number(formData.b);
        //formData.sum = parseFloat(formData.a) + parseFloat(formData.b);
        formData.ACCONTO = (parseFloat(formData.COSTOPROGETTO) * (parseFloat(formData.ACCONTOPERC) / 100) )
    },
    updateTEST: function(params, schema, uiSchema, formData) {
        console.log('qui');
    },
};

const fields_grid = {
    layout_grid: LayoutGridField
}

const widgets = {
    //ImageUpload: ImageUploadWidget
}
  
let allFields = Object.assign({}, fields, fields_grid)

//const ThemedForm = withTheme(Bootstrap4Theme)
const Form = withTheme(Bootstrap4Theme)

let FormWithConditionals = applyRules(schema, uiSchema, rules, Engine, extraActions, allFields)(Form);

class FormPaymentsComp extends React.Component {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
          isLoading: false,
          el_id: false,
          source: '',
          sourceNew: '',
          layout: '',
          //formSchema: {},
          formUiSchema: {},
          formData: {},
        };
        //this.setFormElements = this.setFormElements.bind(this);
        //this.openSlidePanel = this.openSlidePanel.bind(this);
        //this.fetchLayout = this.fetchLayout.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        //this.setState({ el_id: this.props.el_id });
        this.fetchData(this.props.el_id)
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.el_id !== prevProps.el_id) {
            this.fetchData(this.props.el_id)
        }
    }

    fetchData(el_id) {
        //console.log(this.state.el_id);
        //if(this.state.el_id)
    
        /*
        this.setState({
          isLoading: true,
        })
        */
        //var bodyobj = {}
        /*
        var { source = '', layout = '' } = this.props
        if(!!this.props.source) {
          var source =this.props.source
        }
        if(!!this.props.layout) {
          var layout =this.props.layout
        }
        if(!!this.props.location && !!this.props.location.state.source) {
          var { source = '' } = this.props.location.state
        }
        */
    
        //if(this.props.layout !== undefined){
        //if(layout.length){
          //var bodyobj = { "layout": this.props.layout, 'where': this.props.layout+'.ID ='+el_id }
          //var bodyobj = { "layout": this.props.layout }
          //if (el_id.length) {
          //if (!!el_id) {
            //bodyobj.where = this.props.layout+'.ID ='+el_id
          //}
    
        //} else {
          //var bodyobj = { "source": this.props.source, 'where': 'ID ='+el_id }
          var bodyobj = {"source": "wp_cl_travels"}
          /*
          if (source.length) {
            var bodyobj = {"source": source}
          } 
          */
          /*else if (source.length) {
            var bodyobj = {"source": sourceFromlocation.source}
          }
          */
          
         if (el_id == "new") {
            this.setState({
                formData: {},
                isLoading: false,
            })
            return false
          } else if (!!el_id) {
            bodyobj.where = 'ID ='+el_id
          }
        //}
    
        var body = JSON.stringify(bodyobj);
    
        fetch(apiUrlRest+'get', {
          method: 'post',
          headers: {'Content-Type':'application/json'},
          body: body
        })
        // We get the API response and receive data in JSON format...
        .then(response => response.json())
        // ...then we update the users state
        .then(data => {
            //var res = data.data.data[0]
            if (this.state.layout.TYPE == 'FORMTABLE') {
                var res = {'table': data.data.data}
            } else  {
                var res = data.data.data[0]
            }
            /*
            var res = data.data.data[0].map(v => {
                parseInt(v, 10)
                });
            */
            if(res.ACCONTI == null) {
                res.ACCONTI = [];
            } else {
               res.ACCONTI = JSON.parse(res.ACCONTI) 
            }
            
            if(res.COSTS == null) {
                res.COSTS = [];
            } else {
               res.COSTS = JSON.parse(res.COSTS) 
            }
            
            this.setState({
                formData: res,
                isLoading: false,
            })
        }
        )
        // Catch any errors we hit and update the app
        .catch(error => this.setState({ error, isLoading: false }));
    }

    onSubmit = ({formData},e) => {
        e.preventDefault();
        //console.log("submitted", formData);

        fetch(apiUrlRest+'write',
        {
          method: 'post',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify(formData)
        })
        // We get the API response and receive data in JSON format...
        .then(response => response.json())
        // ...then we update the users state
        .then(data => {
          this.setState({
            isLoading: false,
            formData: data.data.data[0],
            //isPaneOpen: false
          })
          this.props.refresh()
          }
        )
        // Catch any errors we hit and update the app
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        //const { isLoading, formSchema, formUiSchema, formData } = this.state;
        var { isLoading, formData } = this.state;
        return (
            <FormWithConditionals
            formData={formData}
            fields={allFields}
            onSubmit={this.onSubmit}
            >
                <div>
                <button type="submit" className="btn-floating" ><i className="far fa-save"></i></button>
                {/*
                <button type="button">Cancel</button>
                */}
                </div>
            </FormWithConditionals>
        )
    }
}

export default FormPaymentsComp
