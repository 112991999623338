import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast'
import { API_URL_REST } from '../_constants/app.constants';

const apiUrlRest = API_URL_REST;

const TourActions = ({ viaggio_corrente }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    const handleClick = async (event) => {
        event.preventDefault();
        
        //console.log(`Button clicked! Current journey: ${viaggio_corrente}`);
        setIsLoading(true);

        const travelid = viaggio_corrente.ID;

        try {
            const response = await fetch(apiUrlRest+'sendPeoplesToSDCreateVoucers', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "travelid": travelid }),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();
            console.log('API response:', data);
        } catch (error) {
            console.error('Error calling WordPress API:', error);
            setHasErrors(true)
        } finally {
            setIsLoading(false);
            setShowToast(true)
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Button variant="primary" onClick={handleClick} disabled={isLoading}>
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            'Invia Persone a SevDesk e Crea Vouchers'
          )}
        </Button>

        {/* Toast for Success or Error Message */}
        <Toast
        style={{
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999
        }}
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className={ // Conditionally assign class based on state
          hasErrors ? 'bg-danger' : 'bg-success'
        }
        >
        {hasErrors ? (
            <>
            <Toast.Header>
                <img src="https://via.placeholder.com/20x20?text=X" className="rounded mr-2" alt="" />
                <strong className="mr-auto">Errore</strong>
            </Toast.Header>
            <Toast.Body>View Console Log</Toast.Body>
            </>
        ) : 
          <>
          <Toast.Header>
              <img src="https://via.placeholder.com/20x20?text=OK" className="rounded mr-2" alt="" />
              <strong className="mr-auto">Inviati</strong>
          </Toast.Header>
          <Toast.Body>I dati sono stati Inviati</Toast.Body>
          </>
        }
        </Toast>
      </div>
    );
};

export default TourActions;