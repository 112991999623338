import React, { useState, Fragment } from 'react'
//import {Route} from 'react-router-dom';

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//import ListComp from './ListComp'
import FormCom from './FormComp'
import {API_URL_REST} from '../_constants/app.constants';

const apiUrlRest = API_URL_REST;

class HomeComp extends React.Component {
    constructor(props, context) {
      super(props, context); 
    }

    render() {
      let user = JSON.parse(localStorage.getItem('user'));
      //console.log(user)
      return (
        <>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand>ClTour</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              {user.record !== undefined && <span>Signed in as {user.record.data.display_name}</span>}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <Container fluid>
          <Row><Col><FormCom {...this.props} /></Col></Row>
        </Container>
        {/* 
        <Container>
            <Row>
                <Col><FormCom {...this.props} /></Col>
                <Col></Col>
            </Row>
        </Container>
        */}
        </>
      );
    }
  }
  
export default HomeComp