import React, { useState, Fragment } from 'react'
import {Route} from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { LinkContainer } from "react-router-bootstrap";

//import NavbarComp from './NavbarComp'
//import TablelistComp from './TablelistComp'
import FormComp from './FormComp'

class CompaniesComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
      };
      this.handleSelect = this.handleSelect.bind(this);
    }
  
    handleSelect(key, persona_id) {
    }

    render() {
      return (
            <div>
                {/*
                <Navbar bg="light" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer to={{
                          pathname: '/destinations/tablelist',
                          state: {source:'wp_cl_destinations', layout:'2'}}}>
                          <Nav.Link>Elenco</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={{
                          pathname: '/destinations/form',
                          state: {source:'wp_cl_destinations', layout:'1'}}}>
                          <Nav.Link>Dettaglio</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
                </Navbar>
                */}
                <Route path={`/companies/tablelist`} component={FormComp}/>
                <Route path={`/companies/form`} component={FormComp}/>
            </div>
      );
    }
  }
  
  //render(<ControlledTabs />);
  export default CompaniesComp