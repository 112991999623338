import React, { useState, Fragment } from 'react'
import {Route} from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { LinkContainer } from "react-router-bootstrap";

import TablelistComp from './TablelistComp'
import FormCom from './FormComp'
import ToursPeoplesImportComp from './ToursPeoplesImportComp'
//import TestCom from './TestComp'

class PeoplesComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        persona_corrente: false
      };
    }

    render() {
      return (
        <div>
          <Navbar bg="light" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                  <LinkContainer to={{
                          pathname: '/peoples/list',
                          state: {source:'wp_cl_peoples'}}}>
                    <Nav.Link>Elenco</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={{
                    pathname: '/peoples/details',
                    state: {source:'wp_cl_peoples'}}}>
                    <Nav.Link>Dettaglio</Nav.Link>
                  </LinkContainer>
                  {/* 
                  <LinkContainer to={{
                    pathname: '/peoples/import',
                    state: {source:'wp_cl_peoples'}}}>
                    <Nav.Link>Importa</Nav.Link>
                  </LinkContainer>
                  */}
              </Nav>
          </Navbar.Collapse>
          </Navbar>
          <Route path={`/peoples/list`} component={FormCom}/>
          <Route path={`/peoples/details`} component={FormCom}/>
          {/*
          <Route path={`/peoples/import`} component={ToursPeoplesImportComp}/>
          */}
        </div>
      );
    }
  }
  
  //render(<ControlledTabs />);
  export default PeoplesComp