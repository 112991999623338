import React, { useState, Fragment } from 'react'
import {Route} from 'react-router-dom';
import { connect } from 'react-redux';

import FormComp from './FormComp'

//import TestCom from './TestComp'

class RoutesComp extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        layout_corrente: false
      };
    }

    render() {
      return (
        <div>
          <Route path={`/routes/tablelist`} component={FormComp}/>
        </div>
      );
    }
  }
  
const mapStateToProps = state => ({
    ...state
});

//render(<RoutesComp />);
//export default RoutesComp
export default connect(mapStateToProps)(RoutesComp);
